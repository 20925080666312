/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ErrorPage.css";

export default function ErrorPage(){
    return(
        <div className="error-page">
            <Container>
                <Row>
        <h1>Oh no!</h1>
        <p>
         
          Sorry, you have reached the page which has either been moved or is under update.<br/><br/>

If you are seeing this error, please write to us at <a href="mailto:inquiry@mortgagepossible.com" aria-label="inquiry@mortgagepossible.com">inquiry@mortgagepossible.com</a> and let us know the page you were trying to access when the error occurred. We apologize for the inconvenience and thank you for your interest in Mortgage Possible.<br/><br/>

Please <a href='/'>click here</a> to go back to the homepage.</p>           
        </Row>
        </Container>
        </div>  
    )
}