import styled, { keyframes } from 'styled-components';
export const Main = styled.main`
  width: 100%;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  align-items: flex-start;
  text-align: justify;
  @font-face {
    font-family: soleil;
    font-weight: 400;
    src: url('../../fonts/SoleilRegular.otf') format('otf');
    font-display: swap;
  }
  @font-face {
    font-family: soleil;
    font-weight: 400;
    src: url('../../fonts/SoleilBold.otf') format('otf');
    font-display: swap;
  }
  font-family: soleil, sans-serif;
  font-weight: 400;
  strong {
    padding: 0vw 0.15vw 0vw 0.15vw;
    font-weight: 700;
  }
`;
export const Background = styled.img`
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  margin-top: -1.5vw;
  height: 80vw;
  @media only screen and (min-width: 600px) {
    height: 45vw;
  }
`;
export const TermsBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 3rem 0rem;
  background: #ffffff;
  width: 90%;
  box-shadow: 0px 0px 40px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  @media only screen and (min-width: 1000px) {
    width: 75%;
    max-width: 90rem;
  }
`;
export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TitleBox = styled.div`
  width: 100%;
  background: rgba(8, 63, 136, 0.09);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const Title = styled.h1`
  font-family: soleil, sans-serif;
  font-weight: 400;
  color: #083f88;
  padding: 1rem;
  font-size: 15px;
  @media only screen and (min-width: 600px) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 2vw;
  }
`;
export const TextBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ pad = '3vw 8.5vw' }) => pad};
  @media only screen and (max-width: 600px) {
    padding: 3vw 7.5vw 1vw 1.5vw  ;
  }
`;
export const TextBoxCap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ pad = '3vw 8.5vw' }) => pad};
`;

export const Text = styled.p`
  line-height: 2;
  color: #083f88;
  width: 100%;
  font-size: 2.42vw;
  @media only screen and (min-width: 600px) {
    line-height: 2;
    font-size: 1.75vw;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1.1vw;
  }
`;

export const Cap = styled.p`
  @media only screen and (max-width: 560px) {
    Margin-bottom:3vw !important;
  }
`;
export const UL = styled.div`
    padding-left: 3em;
`;
export const CapLink = styled.div`
@media only screen and (max-width: 599px) {
   text-align:left;
}
`;
export const CapLinkText = styled.div`
@media only screen and (max-width: 559px) {
   margin-bottom:1.3vw;
}
`;

export const Subtitle = styled.h3`
  font-weight: bold;
  line-height: 130%;
  color: #083f88;
  margin-top: 0.5rem;
  text-align: center;
  font-size: 3vw;
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  @media only screen and (min-width: 600px) {
    font-size: 1.5vw;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1.15vw;
  }
`;

export const SubtitleBox = styled.div`
  width: calc(100% - 17vw);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const Icon = styled.img`
  height: 100%;
  margin: 0;
  padding: 0;
`;
export const UpThumb = styled(Icon)``;
export const DownThumb = styled(Icon)``;
const up = keyframes`
0%{transform:translateY(10%);}
100%{transform:translateY(-10%);transform:rotate(-10deg);}
`;
const down = keyframes`
0%{transform:translateY(-10%);}
100%{transform:translateY(10%);transform:rotate(-10deg);}
`;
export const IconBox = styled.div`
  margin: 0vw 2vw 0vw 0vw;
  padding: 0vw;
  width: 8vw;
  height: 8vw;
  @media only screen and (min-width: 600px) {
    width: 3vw;
    height: 3vw;
    margin: 0vw 1vw 0vw 0vw;
  }
`;
export const OrderedList = styled.ol`
  color: #083f88;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: ${({ pad = '0vw 8.5vw 2vw 8.5vw' }) => pad};
  list-style-position: inside;
`;

export const LineItem = styled.li`
  color: #083f88;
  line-height: 132%;
  padding: ${({ pad = '0vw' }) => pad};
  font-size: 2.45vw;
  @media only screen and (min-width: 600px) {
    font-size: 2vw;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1.1vw;
  }
`;

export const ThumbBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 1vw;
  &:hover ${UpThumb} {
    animation: ${up} 0.5s linear 0s alternate infinite;
  }
  &:hover ${DownThumb} {
    animation: ${down} 0.5s linear 0s alternate infinite;
  }
`;
export const ThumbText = styled(Subtitle)`
  padding: 0;
  margin: 0;
  line-height: 110%;
  padding-bottom: 0.25rem;
`;
export const ListBox = styled.div`
  padding: 2rem 0rem 0rem 0rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 70vw;
  @media only screen and (min-width: 600px) {
    height: 45vw;
  }
  @media only screen and (min-width: 1000px) {
    height: 22.5vw;
  }
  @media (min-width : 1900px){
    height: auto;
  }
`;
export const Line = styled.img`
  height: 90%;
  margin-top: 0.5rem;
`;
export const RightBox = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 5vw;
  @media only screen and (min-width: 600px) {
    margin-top: 2vw;
  }
`;
export const LeftBox = styled(RightBox)`
  align-items: flex-end;
  margin-top: -3vw;
  @media only screen and (min-width: 600px) {
    margin-top: -1vw;
  }
`;