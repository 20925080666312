import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import SuperCharge from "./components/Events/SuperCharge/PreEvent/EventHome";
import LoanProducts from "./components/LoanProducts/LoanProducts";
import Disclaimer from "./components/Disclaimer";
import TexasDisclosure from "./components/TexasDisclosure";
import TermsAndConditions from "./components/TermsAndConditions";
import Privacy from "./components/PrivacyPolicy/PrivacyPolicy";
import Pricing from "./components/BrokerPricing/BrokerPricing";
import CutoffDates from "./components/CutoffDates/CutoffDates";
import TurnTimes from "./components/TurnTimes/TurnTimes";
import ApprovedWriters from "./components/ApprovedUnderwriters/ApprovedUnderwriters";
import "./App.css";
import TruTerms from "./components/TruTerms";
import WholeSaleForms from "./components/SubmissionChecklist/SubmissionChecklist";
import PropertyInsurance from "./components/PropertyInsurance/PropertyInsurance";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import WholesaleProductGuideLines from "./components/Guidelines/DocumentViewer";
import AngelaiTrainings from "./components/AngelAiTraining/AngelAiTraining"
import { CookieSetting } from "cookiesetting-component";

function App() {
  return (
    <React.Fragment>
        <Layout>
        <Routes> 
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />      
          <Route path="/loan-products" element={<LoanProducts />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/Txdis" element={<TexasDisclosure />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/tpo/priceengine" element={<Pricing />} />
          <Route path="/tru-terms" element={<TruTerms />} />
          <Route path="/guidelines" element={<WholesaleProductGuideLines />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/cutoff-dates" element={<CutoffDates />} />
          <Route path="/turn-time-commitment" element={<TurnTimes />} />
          <Route path="/wholesale-lending-forms" element={<WholeSaleForms />} />
          <Route path="/property-insurance" element={<PropertyInsurance />} />
          <Route path="/approved-title-underwriters" element={<ApprovedWriters />} />
          <Route path="/supercharge" element={<SuperCharge />} />
          <Route path="/how-to-hub" element={<div className="route-margin" style={{backgroundColor: "#303030"}}>
            <AngelaiTrainings />
          </div>} />
          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
        <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
      </Layout>
    </React.Fragment>
  );
}
export default App;
