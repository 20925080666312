import React, { useEffect, useState, memo } from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import './CutoffDates.css';
import styled from 'styled-components';

const TableWrapper = styled.div`
  .table-responsive > .table-bordered {
    border: 1px solid #FF691A !important;
        --bs-table-hover-bg:rgba(255, 105, 26, 0.10);
  }
       
 
  .table-bordered thead td,
  .table-bordered thead th {
    min-width: 100px !important;
    vertical-align: top !important;
  }
    
  th {
    background: #8F3E1A !important;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    border: 1px solid #FFF !important;
    border-top: 0px !important;
    padding: 0.60rem;
  }
  td {
    padding: 0.50rem;
    border: 1px solid #FF691A !important;
    font-size: 15px;
    background: white;
  }
  .table-responsive > .table-bordered {
    border: 0;
    font-size: 15px;
  }
  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    background: rgba(255, 105, 26, 0.10) !important;
  }
  @media (max-width: 767px) {
    .table-bordered thead td,
    .table-bordered thead th {
      min-width: auto !important;
      vertical-align: top !important;
    }
    .table-bordered > thead > tr > th:first-child {
      width: auto !important;
    }
  }
  @media (max-width: 1024px) {
    .table-bordered > thead > tr > th:first-child {
      width: auto !important;
    }
  }
`;

function CutOffDates() {
  const [cutoffData, setCutoffData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCutOffData();
  }, []);

  const fetchCutOffData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_CUTOFF_DATES_JSON); // Replace with the actual URL to your JSON
      setCutoffData(response.data);
    } catch (error) {
      console.error('Error fetching cut off data:', error);
    }
  };

  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const monthArray = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const clicked1 = (e) => {
    let target = e.target.getAttribute('id');
    if (target != null) {
      let scrollId = document.getElementById('question' + target).offsetTop;
      window.scrollTo({
        top: scrollId - 80,
        behavior: 'smooth',
      });
    }
  };

  const tableHeader = [
    'Loan Type and Purpose', 'Last Day for Loan Submission', 'Last Day to Submit Conditions',
    'Last Day to send LE', 'Last Day to Request CD',
    'Last Day for CD to be signed / acknowledged by borrower <sup><a id="1" style="color:white">1</a></sup>,<sup><a id="2" style="color:white">2</a></sup>',
    'Last Day to Submit Doc Request', 'Last Day to Sign Docs<sup><a id="3" style="color:white">3</a></sup>',
    'Last Day to Receive Signed Docs Back<sup><a id="3" style="color:white">3</a></sup>'
  ];
  
  let tableBody;
  let cutOffDesc = [];

  if (cutoffData !== null) {
    tableBody = cutoffData.map((ele) => {
      if (ele.CD_SIGN_NOTES && !cutOffDesc.includes(ele.CD_SIGN_NOTES)) {
        cutOffDesc.push(ele.CD_SIGN_NOTES);
      }
      if (ele.DOCS_RECIEVED_SIGN_NOTES && !cutOffDesc.includes(ele.DOCS_RECIEVED_SIGN_NOTES)) {
        cutOffDesc.push(ele.DOCS_RECIEVED_SIGN_NOTES);
      }

      return {
        td1: `${ele.LOAN_TYPE} ${ele.LOAN_PURPOSE}`,
        td2: ele.LOAN_SUBMIT_DATE,
        td3: ele.CONDITION_SUBMIT_DATE,
        td4: ele.LE_SEND_DATE,
        td5: ele.CD_REQUEST_DATE,
        td6: ele.CD_SIGN_DATE,
        td7: ele.DOCS_REQUEST_DATE,
        td8: ele.DOCS_SIGN_DATE,
        td9: ele.DOCS_RECIEVED_SIGN_DATE,
      };
    });
  }

  return (
    
    <div className='cut-off-section'>
      <title>
        Mortgage Possible: Your Way to Great Rates and Hassle-Free Mortgages
      </title>
    <div className='cut-off-banner'>
        
       <Container fluid className='cut-off-banner-container'>
      <div className="hero_image cut-off-image">
        <div>
          <h1 className='text-center'>Cutoff Dates</h1>
        </div>
      </div>
      </Container> 
      </div>
    
      <Container fluid className="pageContainer mt-3">
        {cutoffData ? (
          <div>
            <Row className="mb-2">
              <h2 className='text-center'>Cutoff Dates for Funding Loans in {monthArray[month]} {year}</h2>
            </Row>
            <p className="mb-2 text-center cut-off-note">
              Note: For funding the loans in {monthArray[month]} {year}, loans must meet the cutoff dates given below.
            </p>
            <TableWrapper className='mt-4'>
              <Table
                striped
                bordered
                hover
                responsive
              >
                <thead>
                  <tr>
                    {tableHeader.map((header, index) => (
                      <th key={index} dangerouslySetInnerHTML={{ __html: header }} />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableBody.map((row, index) => (
                    <tr key={index}>
                      <td>{row.td1}</td>
                      <td>{row.td2}</td>
                      <td>{row.td3}</td>
                      <td>{row.td4}</td>
                      <td>{row.td5}</td>
                      <td>{row.td6}</td>
                      <td>{row.td7}</td>
                      <td>{row.td8}</td>
                      <td>{row.td9}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
            <div className='text-center'>
              <p style={{ marginTop: '20px', marginBottom: '25px' }}>
                <p id="question1">{cutOffDesc[0]}</p>
                {cutOffDesc[2] ? (
                  <p id="question2">{cutOffDesc[2]}</p>
                ) : (
                  <p>3 Last day to Receive Signed Docs Back and Last Day to Sign Docs does not apply for wet purchase.</p>
                )}
                <p id="question3">{cutOffDesc[1]}</p>
              </p>
            </div>
          </div>
        ) : null}
          </Container>
    </div>
  );
}

export default memo(CutOffDates);
