const getPSTTimestamp = dateTimeStr => {
    const dateTime = new Date(dateTimeStr);
    const pstDateTime = dateTime.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    });
    return new Date(pstDateTime);
  };
  
  
  export function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  
  export default getPSTTimestamp;