import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { ASKSIMPERS_AI_REDIRECT_URL, isMobileOrTablet, isDBAApprovedState } from "../Utils";
import { Link } from 'react-router-dom';
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu/index.js";
import "./NavigationBar.css";

export default function NavigationBar() {
    const [navbar, setNavbar] = useState(false);
    const isMobileOrTabletDevice = isMobileOrTablet();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isApprovedState, setIsApprovedState] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const imgCDNURL = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE;
    const PUBLIC_URL = process.env.PUBLIC_URL;

    const openLoginModal = () => {
        const modalTop = window.pageYOffset + 20; // Adjust this value as needed
        // Show the modal
        setShowLoginModal(true);
    };

    let prevScrollPos = window.scrollY;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dbaApproved = await isDBAApprovedState();
                setIsApprovedState(dbaApproved);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsApprovedState(false);
            }
        };
    
        fetchData();
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    
    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const menuNavbar = document.getElementById("menuNavbar");
        if (currentScrollPos === 0) {
            menuNavbar.classList.remove("navbarTransition");
            setNavbar(false);
        } else {
            if (prevScrollPos > currentScrollPos) {
                setNavbar(true);
            } else if (prevScrollPos < currentScrollPos) {
                if (!isMobileOrTabletDevice) {
                    setNavbar(true);
                } else {
                    setNavbar(false);
                    menuNavbar.classList.add("navbarTransition");
                }
            }
        }
        prevScrollPos = currentScrollPos;
    };

    const handleMenuClick = () => {
        setExpanded(false);
        setNavbar(false);
    };
    const handleNoButtonClick = () => {
        window.open(
            'https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/',
            '_blank',
        );
        closeLoginModal();
    };
    const closeLoginModal = () => {
        setShowLoginModal(false);
    };
    const handleYesButtonClick = () => {
        window.open(
            'https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/',
            '_blank',
        );
        closeLoginModal();
    };

    return (
        <>
            <div>
                <Navbar
                    id="menuNavbar"
                    collapseOnSelect
                    expanded={expanded}
                    onToggle={() => setExpanded(!expanded)}
                    onSelect={() => setExpanded(false)}
                    expand="lg"
                    fixed="top"
                    bg="dark"
                    variant="dark"
                    className={navbar ? "navbar active" : "navbar"}
                >
                    <Container className="navbar-container">
                        <Navbar.Brand className="navbrand-logo">
                            <div className="d-flex">
                                <div>
                                    <Link to="/" onClick={() => setExpanded(false)}>
                                        {!isApprovedState ? <img
                                            src={imgCDNURL + "assets/mortgage-possible-dba-not-approved-white.webp"}
                                            alt="MortgagePossible logo"
                                            width="200"
                                            height="74"
                                            className="main_logo img-fluid"
                                        /> : <img
                                            src={imgCDNURL + "assets/mortgage-possible-dba-approved-white.webp"}
                                            alt="MortgagePossible logo"
                                            width="180"
                                            height="67"
                                            className="main_logo img-fluid"
                                        />}

                                    </Link>
                                </div>
                             
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav>
                                <Nav.Link as={Link} to="/" onClick={handleMenuClick}>
                                    HOME
                                </Nav.Link>
                                <Nav.Link
                                    href={process.env.REACT_APP_PARTNER_LINK}
                                    target="_blank"
                                    onClick={handleMenuClick}
                                >
                                    PARTNER LOGIN
                                </Nav.Link>
                                <NavDropdown title="RESOURCES">
                                    {/* <NavDropdown.Item as={Link} to={PUBLIC_URL + "/loan-calculators-mortgage-calculators"} target="_blank" rel="noopener noreferrer">Calculators</NavDropdown.Item> */}
                                    <NavDropdown.Item as={Link} to={PUBLIC_URL + "/loan-products"} target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Loan Products</NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to="https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/" target="_blank" rel="noopener noreferrer" onClick={(e) => {
                                        e.preventDefault(); // Prevent the default action of opening the link
                                        openLoginModal(); // Open the modal instead
                                    }} >Glossary</NavDropdown.Item> */}
                                    {/* <NavDropdown.Item as={Link} to={PUBLIC_URL + "/faq"} target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>FAQ</NavDropdown.Item> */}
                                    {/* <NavDropdown.Item as={Link} to={PUBLIC_URL + "/turn-time-commitment"} target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Turn Time commitment</NavDropdown.Item> */}

                                    <NavDropdown.Item as={Link} to={ASKSIMPERS_AI_REDIRECT_URL} target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>AskSimpers.Ai</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/tpo/priceengine" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Pricing Engine</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/guidelines" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Product Guidelines</NavDropdown.Item>
                                   <NavDropdown.Item as={Link} to="/cutoff-dates" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Cut Off Dates</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/turn-time-commitment" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Turn Time Commitment</NavDropdown.Item>  
                                    <NavDropdown.Item as={Link} to="/property-insurance" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Property Insurance</NavDropdown.Item> 
                                    <NavDropdown.Item as={Link} to="/approved-title-underwriters" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Approved Master Title <br/>Underwriters</NavDropdown.Item> 
                                    <NavDropdown.Item as={Link} to="/wholesale-lending-forms" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Submission Checklist and <br/>Document Templates</NavDropdown.Item> 
                                    <NavDropdown.Item as={Link} to="https://revmort.reversesoftonline.com/ReverseMortgage/jsp/extCalculator.jsf?compCde=SUNWST000" target="_blank" rel="noopener noreferrer" onClick={handleMenuClick}>Reverse Mortgage Calculator</NavDropdown.Item> 
                                    </NavDropdown>
                                    

                                <Nav.Link as={Link} to="/loan-products" onClick={handleMenuClick}>
                                    PRODUCTS
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                    <div className="desktop-AccessibilityMenu-show">
            <UserWayAccessibilityMenu color="white" width={40} height={40}/>
          </div>
          </Navbar>
          <div className="mobile-AccessibilityMenu-show">
        <UserWayAccessibilityMenu color="white" width={40} height={40}/>
        </div>
                {showLoginModal && (
                    <div className="login_modal">
                        <div className="login_modal_content">
                            <button className="close_button" onClick={closeLoginModal}>
                                X
                            </button>
                            <p className="login_modal_context">
                                ALL OF YOUR INTERACTION WITH THIS SOFTWARE IS BEING RECORDED. By
                                using this software/website/mobile app, you agree to the Mortgage Possible
                                NMLS 3277 and Celligence International, LLC.'s ("Celligence"){' '}
                                <a
                                    href="https://angelai.com/terms-and-conditions"
                                    target="_blank"
                                >
                                    Terms and Conditions
                                </a>{' '}
                                and{' '}
                                <a href="https://www.celligence.com/privacy" target="_blank">
                                    Security Policy
                                </a>
                                ; all of which you may access by clicking on the "Terms and
                                Conditions" or "Security Policy" web links contained herein.
                            </p>
                            <div className="login_modal_buttons">
                                <Button
                                    onClick={handleNoButtonClick}
                                    className="No_btn btn btn-secondary"
                                    id="No_btn"
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={handleYesButtonClick}
                                    className="Yes_btn btn btn-primary"
                                    id="Yes_btn"
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
