import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { isDBAApprovedState } from "../Utils";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import './SubmissionChecklist.css';

export function WholeSaleForms() {
  const [documents, setDocuments] = useState({ submissionChecklists: [], documentTemplates: [] });
  const [isApprovedState, setIsApprovedState] = useState(true);

  useEffect(() => {
    // Fetch documents from JSON
    axios
      .get(process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + 'jsons/submissionchecklist.json')
      .then(response => {
        setDocuments(response.data);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbaApproved = await isDBAApprovedState();
        setIsApprovedState(dbaApproved);
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
        setIsApprovedState(false); // Set state accordingly
      }
    };

    fetchData();
  }, []);

  const getDocumentPath = (doc) => {
    const dbaPath = doc.dbaPath;
    const nonDbaPath = doc.nonDbaPath;
    
    return isApprovedState ? dbaPath : nonDbaPath;
  };

  // Split document templates into two halves
  const midPoint = Math.ceil(documents.documentTemplates.length / 2);
  const firstHalf = documents.documentTemplates.slice(0, midPoint);
  const secondHalf = documents.documentTemplates.slice(midPoint);

  return (
    <article>
      <Helmet>
        <title>
          Mortgage Possible - Related Forms and Financial Documents
        </title>
        <meta
          name="description"
          content="A React.js Boilerplate application homepage"
        />
        <meta
          property="og:url"
          content="https://www.swmc.com/wholesale-lending-forms"
        />
        <meta
          property="og:title"
          content="Mortgage Possible - Wholesale Lending Forms"
        />
        <meta
          property="og:description"
          content="Mortgage Possible offers residential home loan mortgages such as purchase, refinance, property rehabilitation loan, and reverse mortgage."
        />
        <meta
          property="og:keyword"
          content="Wholesale Lending Forms, swmc forms, lending forms"
        />
        <meta property="og:brand" content="Mortgage Possible Mortgage Company. Inc" />
      </Helmet>
    
      <div className='title-underwriter-section'>
      <div className='cut-off-banner'>
        <Container className="hero_image1">
          <h1>Submission Checklist and<br />Document Templates</h1>
        </Container>
      </div>
      

        <Container className="pageContainer">
          <div className= 'submission-checklist-section'>
           
            <h2>Submission Checklist</h2>
            <ul className='submission-checklist-lists'>
              {documents.submissionChecklists.map(doc => (
                <li key={doc.title}>
                  <a
                    href={getDocumentPath(doc)}
                    target="_blank"
                    title={doc.title}
                  >
                    {doc.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-5 document-templates-section">
            <h2>Document Templates</h2>
            <Row className='document-templates-row'>
              <Col xs={12} lg={6}>
                <ul>
                  {firstHalf.map(doc => (
                    <li key={doc.title}>
                      <a
                        href={getDocumentPath(doc)}
                        target="_new"
                        title={doc.title}
                      >
                        {doc.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col xs={12} lg={6}>
                <ul>
                  {secondHalf.map(doc => (
                    <li key={doc.title}>
                      <a
                        href={getDocumentPath(doc)}
                        target="_new"
                        title={doc.title}
                      >
                        {doc.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </article>
  );
}

export default WholeSaleForms;
