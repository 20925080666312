import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { isDBAApprovedState } from "../Utils";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HTMLReactParser from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import './PropertyInsurance.css'; // Import your CSS file for styles

const PropertyInsurance = ({ location }) => {
  const [propertyLocations, setPropertyLocations] = useState([]);
  const [isApprovedState, setIsApprovedState] = useState(true);

  useEffect(() => {
    const fetchPropertyLocations = async () => {
      try {
        const response = await axios.get('https://prod-cdn.swmc.com/mortgagepossible/jsons/propertyLocations.json');
        setPropertyLocations(response.data);
      } catch (error) {
        console.error('Error fetching property locations:', error);
      }
    };

    fetchPropertyLocations();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const dbaApproved = await isDBAApprovedState();
            setIsApprovedState(dbaApproved);
        } catch (error) {
            // Handle error
            console.error("Error fetching data:", error);
            setIsApprovedState(false); // Set state accordingly
        }
    };

    fetchData();
}, []);

  useEffect(() => {
    const handleScroll = () => {
      if (location && (location.hash || location.params)) {
        const hashPosition = location.hash || location.params;
        const id = hashPosition.replace('#', '');
        const scrollElement = document.getElementById(id);
        if (scrollElement) {
          window.scrollTo(0, scrollElement.offsetTop - 90);
        }
      } else {
        window.scrollTo(0, 0);
      }
    };

    handleScroll();
  }, [location]);

  return (
    <div className='Property-Insurance-section'>
        <title>
        Mortgage Possible: Your Way to Great Rates and Hassle-Free Mortgages
      </title>
         <div className='cut-off-banner'>
      <Container className="hero_image1">
        <h1>Property Insurance</h1>
      </Container>
      </div>
      <Container className="page_container">
        <Row className='justify-content-center'>
          {propertyLocations.map((location, index) => (
            <Col key={index} md={4} sm={6} xs={12}>
              <div className={location.className}>
                <div className="imgsec">
                  <img
                    alt="location icon"
                    src={location.imgSrc}
                    className="homepageImg"
                  />
                </div>
                <div className="contentsection">
                  <h5>{HTMLReactParser(location.title)}</h5>
                  {!isApprovedState ?    <p>{HTMLReactParser(location.nondbaContent)}</p> : <p>{HTMLReactParser(location.dbaContent)}</p>}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default PropertyInsurance;
