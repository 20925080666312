import React, { useState, useEffect, useRef, useCallback } from 'react';
import mammoth from 'mammoth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import './DocumentViewer.css';

const Modal = React.memo(({ isOpen, content, onClose }) => {
  useEffect(() => {
    if (content && isOpen) {
      const wrapper = document.createElement('div');
      wrapper.className = 'table-wrapper';
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;

      const tables = tempDiv.getElementsByTagName('table');
      for (let table of tables) {
        const tableParent = table.parentNode;
        wrapper.innerHTML = table.outerHTML;
        tableParent.replaceChild(wrapper.cloneNode(true), table);
      }

      const modalContent = document.querySelector('.modal-content .content');
      if (modalContent) {
        modalContent.innerHTML = tempDiv.innerHTML;
      }
    }
  }, [content, isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <button onClick={onClose} className="close-button-top">X</button>
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
});

const DocumentViewer = () => {
  const [documentsData, setDocumentsData] = useState([]);
  const [htmlContent, setHtmlContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedTitles, setExpandedTitles] = useState({});
  const [expandedHeadings, setExpandedHeadings] = useState({});
  const [expandedProducts, setExpandedProducts] = useState({});
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const documentContentRef = useRef(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GUIDELINES_JSON);
        const data = await response.json();
        setDocumentsData(data);

        if (data.length > 0 && data[0].data.length > 0 && data[0].data[0].products.length > 0 && data[0].data[0].products[0].programs.length > 0) {
          const firstProgram = data[0].data[0].products[0].programs[0];
          const firstProgramId = `${data[0].title}-${data[0].data[0].heading}-${firstProgram.name}`;

          const isMobile = window.innerWidth <= 992;
          if (!isMobile) {
            handleClickProgram(firstProgram, firstProgramId, null);
            setExpandedTitles({ [0]: true });
            setExpandedHeadings({ [0]: { [0]: true } });
            setExpandedProducts({ [0]: { [0]: { [0]: true } } });
            setSelectedProgram(firstProgramId);
          }
        }
      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    };

    fetchDocuments();
  }, []);

  const handleClickProgram = useCallback(async (item, programId, event) => {
    if (event) {
      setCursorPos({ x: event.clientX, y: event.clientY });
    }
    setLoading(true);

    try {
      const response = await fetch(item.docxFile);
      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      const isMobile = window.innerWidth <= 992;

      setHtmlContent(result.value);
      setIsModalOpen(isMobile);

      if (documentContentRef.current && !isMobile) {
        documentContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (!isMobile) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }

      setSelectedProgram(programId);
      setSelectedProduct(programId);
    } catch (error) {
      console.error('Error handling document click:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setHtmlContent(null);
  }, []);

  const toggleTitle = useCallback((index) => {
    setExpandedTitles((prev) => ({ ...prev, [index]: !prev[index] }));
  }, []);

  const toggleHeading = useCallback((titleIndex, headingIndex) => {
    setExpandedHeadings((prev) => ({
      ...prev,
      [titleIndex]: { ...prev[titleIndex], [headingIndex]: !prev[titleIndex]?.[headingIndex] },
    }));
  }, []);

  const toggleProduct = useCallback((titleIndex, headingIndex, productIndex) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [titleIndex]: {
        ...prev[titleIndex],
        [headingIndex]: {
          ...prev[titleIndex]?.[headingIndex],
          [productIndex]: !prev[titleIndex]?.[headingIndex]?.[productIndex],
        },
      },
    }));
  }, []);

  return (
    <>
    <div className='guidelines-page'>
      <div><h1 className='text-center product-heading'>Product Guidelines</h1></div>
      <div className="document-viewer-container">
        <div className="document-list">
          {documentsData.map((doc, titleIndex) => (
            <DocumentTitle
              key={titleIndex}
              doc={doc}
              onClickProgram={handleClickProgram}
              isExpanded={!!expandedTitles[titleIndex]}
              toggleTitle={() => toggleTitle(titleIndex)}
              expandedHeadings={expandedHeadings[titleIndex] || {}}
              toggleHeading={(headingIndex) => toggleHeading(titleIndex, headingIndex)}
              expandedProducts={expandedProducts[titleIndex] || {}}
              toggleProduct={(headingIndex, productIndex) => toggleProduct(titleIndex, headingIndex, productIndex)}
              selectedProgram={selectedProgram}
              selectedProduct={selectedProduct}
            />
          ))}
        </div>
        <div className="document-content">
          {loading ? (
            <div className="spinner-container" style={{ position: 'fixed', top: cursorPos.y, left: cursorPos.x, marginLeft: '15px'}}>
              <ClipLoader size={30} color={"#123abc"} loading={loading} />
            </div>
          ) : (
            !isModalOpen && htmlContent && (
              <div className="html-content" ref={documentContentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
            )
          )}
        </div>
        <Modal isOpen={isModalOpen} content={htmlContent} onClose={handleCloseModal} />
      </div>
      </div>
    </>
  );
};

const DocumentTitle = React.memo(({ doc, onClickProgram, isExpanded, toggleTitle, expandedHeadings, toggleHeading, expandedProducts, toggleProduct, selectedProgram, selectedProduct }) => {
  return (
    <div className="document-title">
      <h2 onClick={toggleTitle} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <div style={{ flex: 1 }}>{doc.title}</div>
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} style={{ marginLeft: '10px' }} className='title-icon' />
      </h2>
      {isExpanded && doc.data.map((heading, headingIndex) => (
        <DocumentHeading
          key={headingIndex}
          heading={heading}
          title={doc.title}
          onClickProgram={onClickProgram}
          isExpanded={!!expandedHeadings[headingIndex]}
          toggleHeading={() => toggleHeading(headingIndex)}
          expandedProducts={expandedProducts[headingIndex] || {}}
          toggleProduct={(productIndex) => toggleProduct(headingIndex, productIndex)}
          selectedProgram={selectedProgram}
          selectedProduct={selectedProduct}
        />
      ))}
    </div>
  );
});

const DocumentHeading = React.memo(({ heading, title, onClickProgram, isExpanded, toggleHeading, expandedProducts, toggleProduct, selectedProgram, selectedProduct }) => {
  return (
    <div className="document-heading">
      <h3 onClick={toggleHeading} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <div style={{ flex: 1 }}>{heading.heading}</div>
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} style={{ marginLeft: '10px' }} />
      </h3>
      {isExpanded && heading.products.map((product, productIndex) => (
        <DocumentProduct
          key={productIndex}
          product={product}
          title={title}
          heading={heading.heading}
          onClickProgram={onClickProgram}
          isExpanded={!!expandedProducts[productIndex]}
          toggleProduct={() => toggleProduct(productIndex)}
          selectedProgram={selectedProgram}
          selectedProduct={selectedProduct}
        />
      ))}
    </div>
  );
});

const DocumentProduct = React.memo(({ product, title, heading, onClickProgram, isExpanded, toggleProduct, selectedProgram, selectedProduct }) => {
  const hasPrograms = product.programs && product.programs.length > 0;

  const handleProductClick = (event) => {
    const productId = `${title}-${heading}-${product.name}`;
    if (hasPrograms) {
      toggleProduct();
    } else {
      onClickProgram(product, productId, event);
    }
  };

  const hasWordDocument = !!product.docxFile;
  const productId = `${title}-${heading}-${product.name}`;
  const isSelectedProduct = hasWordDocument && selectedProduct === productId;
  const productClassName = isSelectedProduct ? 'product-name selected-product' : 'product-name';

  return (
    <div className="document-product">
      <h4
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleProductClick}
        className={productClassName}
      >
        <div style={{ flex: 1 }}>{product.name}</div>
        {hasPrograms && (
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} style={{ marginLeft: '10px' }} />
        )}
      </h4>
      {isExpanded && hasPrograms && product.programs.map((program, programIndex) => (
        <div
          key={programIndex}
          className={`document-program ${selectedProgram === `${title}-${heading}-${program.name}` ? 'selected' : ''}`}
          onClick={(event) => onClickProgram(program, `${title}-${heading}-${program.name}`, event)}
        >
          <p>{program.name}</p>
        </div>
      ))}
    </div>
  );
});

export default DocumentViewer;
