import React, { useEffect, memo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { businessDayDescriptionDisplay, imagePath } from '../Utils/WHL/helpers';

const StyleWrapper = styled.div`
  .table-responsive > .table-bordered {
    border: 1px solid #FF691A !important;
  }
    .table-responsive{
        margin-top: -16px;
    
    }
  .table-bordered > :not(caption) > * {
    border-width: 1px 0 !important;
    border-color: #FF691A !important;
  }
  table tr th {
    background-color: #FF691A !important;
    color: #000;
    text-align: center;
    font-size: 14px;
  
  }
  tr {
    text-align: center;
  }
  td {
    vertical-align: middle;
    text-align: center;
     border: 1px solid #FF691A !important;
  }
  .initial_underwrite_Star_Header {
    color: #00008b !important;
  }
  .initial_underwrite_Star_Notes {
    color: #00008b !important;
  }
  .condition_resolution_Star_Header {
    color: #fff !important;
  }
  .condition_resolution_Star_Notes {
    color: #888a8c;
  }
  .funding_Star_Header {
    color: #ffa500 !important;
  }
  .funding_Star_Notes {
    color: #ffa500;
  }
  .two-hour-icon,
  .four-hour-icon,
  .eight-hour-icon,
  .twenty-four-hour-icon {
    width: 45px;
  }
  .underWritten-on-demand {
    width: 130px;
    height: 130px;
        display: block;
    margin-left: auto;
    margin-right: auto;
}
  }
  .condition-review-on-demand {
    width: 130px;
    height: 130px;
        display: block;
    margin-left: auto;
    margin-right: auto;
}
  }
  .fourty-mins-icon {
    width: 100px;
  }
  .notesList {
    list-style: none;
    padding-left: 0;
  }
  .notesList li {
    color: #939393;
  }
  @media (max-width: 767px) {
    .two-hour-icon,
    .four-hour-icon,
    .eight-hour-icon,
    .twenty-four-hour-icon {
      width: 40px;
    }
  table tr th {
   font-size: 14px !important;
   
  }
    .underWritten-on-demand {
      width: 100px;
      height: 100px;
    }
    .condition-review-on-demand {
      width: 100px;
      height: 100px;
    }
  }
`;

const TurnTimes = (props) => {
  const [turnTimesProduct, setTurnTimesProduct] = useState([]);
  const [currentTurnTimesError, setCurrentTurnTimesError] = useState(null);
  const [initialUnderwriteNoteslist, setInitialUnderwriteNoteslist] = useState([]);
  const [conditionResolutionNoteslist, setConditionResolutionNoteslist] = useState([]);
  const [fundingNoteslist, setFundingNoteslist] = useState([]);

  const [initialUnderwriteNoteEqualToAll, setInitialUnderwriteNoteEqualToAll] = useState(false);
  const [conditionResolutionNoteEqualToAll, setConditionResolutionNoteEqualToAll] = useState(false);
  const [fundingNoteEqualToAll, setFundingNoteEqualToAll] = useState(false);

  const descriptionToDisplay = (turnTimeProductData) => {
    let turn_time_description_ToShow = '';
    const { turn_time_hours, turn_time_description, notes } = turnTimeProductData;

    if (turn_time_hours || turn_time_hours === 0) {
      turn_time_description_ToShow = turn_time_hours !== '' ? businessDayDescriptionDisplay(turn_time_hours) : turn_time_description;
    } else {
      turn_time_description_ToShow = turn_time_description;
    }

    return turn_time_description_ToShow;
  };

  const getCurrrentTurnTimesDetails = () => {
    try {
      const turn_times = props.timesJson;

      // Check if the data exists
      if (!turn_times || !turn_times.current_times || !turn_times.current_times.program_name) {
        setCurrentTurnTimesError('Data is undefined or null');
        return;
      }

      const programNameListWithDetails = turn_times.current_times.program_name;
      const findOnlyProgramName = props.programName;

      if (!programNameListWithDetails[findOnlyProgramName]) {
        setCurrentTurnTimesError(`Program name ${findOnlyProgramName} not found in data`);
        return;
      }

      const filtered = programNameListWithDetails[findOnlyProgramName];

      const turnTimesProductListToShow = Object.keys(filtered).map((key) => ({
        ...filtered[key],
        Program_Name: key,
      }));

      const initialUnderwrite = [];
      const conditionResolution = [];
      const funding = [];

      let initialUnderwriteStarCount = 1;
      let conditionResolutionStarCount = 1;
      let fundingStarCount = 1;

      turnTimesProductListToShow.forEach((product, index) => {
        product.initial_underwrite.turn_time_description_ = descriptionToDisplay(product.initial_underwrite);
        if (product.initial_underwrite.notes.trim() !== '') {
          initialUnderwrite.push({
            id: index,
            notes: product.initial_underwrite.notes,
            notes_WithStar: '',
          });
          product.initial_underwrite.turn_time_description__ForStar = descriptionToDisplay(product.initial_underwrite);
          product.initial_underwrite.turn_time_description__StarToDisplay = '';
          initialUnderwriteStarCount++;
        } else {
          product.initial_underwrite.turn_time_description__ForStar = descriptionToDisplay(product.initial_underwrite);
        }

        product.condition_resolution.turn_time_description_ = descriptionToDisplay(product.condition_resolution);
        if (product.condition_resolution.notes.trim() !== '') {
          conditionResolution.push({
            id: index,
            notes: product.condition_resolution.notes,
            notes_WithStar: '',
          });
          product.condition_resolution.turn_time_description__ForStar = descriptionToDisplay(product.condition_resolution);
          product.condition_resolution.turn_time_description__StarToDisplay = '';
          conditionResolutionStarCount++;
        } else {
          product.condition_resolution.turn_time_description__ForStar = descriptionToDisplay(product.condition_resolution);
        }

        product.funding.turn_time_description_ = descriptionToDisplay(product.funding);
        if (product.funding.notes.trim() !== '') {
          funding.push({
            id: index,
            notes: product.funding.notes,
            notes_WithStar: '',
          });
          product.funding.turn_time_description__ForStar = descriptionToDisplay(product.funding);
          product.funding.turn_time_description__StarToDisplay = '';
          fundingStarCount++;
        } else {
          product.funding.turn_time_description__ForStar = descriptionToDisplay(product.funding);
        }
      });

      setInitialUnderwriteNoteslist(initialUnderwrite);
      setConditionResolutionNoteslist(conditionResolution);
      setFundingNoteslist(funding);

      const initialUnderwriteCompare = compareObject(initialUnderwrite);
      const conditionResolutionCompare = compareObject(conditionResolution);
      const fundingCompare = compareObject(funding);

      if (initialUnderwriteCompare && conditionResolutionCompare && fundingCompare) {
        const firstProduct = turnTimesProductListToShow[0];
        firstProduct.initial_underwrite.turn_time_description__StarToDisplay = '';
        firstProduct.condition_resolution.turn_time_description__StarToDisplay = '';
        firstProduct.funding.turn_time_description__StarToDisplay = '';

        firstProduct.initial_underwrite.turn_time_description__ForStar = descriptionToDisplay(
          firstProduct.initial_underwrite
        );
        firstProduct.condition_resolution.turn_time_description__ForStar = descriptionToDisplay(
          firstProduct.condition_resolution
        );
        firstProduct.funding.turn_time_description__ForStar = descriptionToDisplay(firstProduct.funding);

        setInitialUnderwriteNoteEqualToAll(true);
        setConditionResolutionNoteEqualToAll(true);
        setFundingNoteEqualToAll(true);

        setTurnTimesProduct([firstProduct]);
      } else {
        setInitialUnderwriteNoteEqualToAll(false);
        setConditionResolutionNoteEqualToAll(false);
        setFundingNoteEqualToAll(false);
        setTurnTimesProduct(turnTimesProductListToShow);
      }
    } catch (error) {
      console.error('Error in getCurrrentTurnTimesDetails:', error);
      setCurrentTurnTimesError(error.message);
    }
  };

  useEffect(() => {
    getCurrrentTurnTimesDetails();
  }, [props.timesJson, props.programName]);

  const compareObject = (objects) => {
    if (objects.length < 3) return false;

    return Object.keys(objects[0]).every((key) =>
      objects.every((obj) => obj[key].trim() === objects[0][key].trim())
    );
  };

  return (
    <StyleWrapper>
      <Container>
        {currentTurnTimesError ? (
          <div className="error-message">{currentTurnTimesError}</div>
        ) : (
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Loan Purpose</th>
                <th>Initial Underwrite Time</th>
                <th>Condition Resolution Time​¹</th>
                <th>Dry Funding²</th>
              </tr>
            </thead>
            <tbody>
              {turnTimesProduct.map((product, index) => (
                <tr key={index}>
                  <td>{product.Program_Name}</td>
                  <td>
                    {/* <div>
                      <img
                        className="initial_underwrite_Star_Header"
                        src={imagePath(product.initial_underwrite.turn_time_description__StarToDisplay)}
                        alt="Initial Underwrite Star"
                      />
                    </div> */}
                    <div>{product.initial_underwrite.turn_time_description_}</div>
                    <div>
                      {initialUnderwriteNoteslist.length > 0 && (
                        <ul className="notesList">
                          {initialUnderwriteNoteslist.map((note) => (
                            <li key={note.id}>{note.notes}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </td>
                  <td>
                    {/* <div>
                      <img
                        className="condition_resolution_Star_Header"
                        src={imagePath(product.condition_resolution.turn_time_description__StarToDisplay)}
                        alt="Condition Resolution Star"
                      />
                    </div> */}
                    {/* <div>{product.condition_resolution.turn_time_description_}</div> */}
                    <div>
                        <img className="condition-review-on-demand" alt='Condition Reviewed & Resolved on Demand'
                        src='https://resources.swmc.com/swmc-images/HomeImages/condition-review-time.webp'  />
                        </div>
                    {/* <div>
                      {conditionResolutionNoteslist.length > 0 && (
                        <ul className="notesList">
                          {conditionResolutionNoteslist.map((note) => (
                            <li key={note.id}>{note.notes}</li>
                          ))}
                        </ul>
                      )}
                    </div> */}
                  </td>
                  <td>
                    {/* <div>
                      <img
                        className="funding_Star_Header"
                        src={imagePath(product.funding.turn_time_description__StarToDisplay)}
                        alt="Funding Star"
                      />
                    </div> */}
                    <div>Same Day </div>
                    <div>
                      {fundingNoteslist.length > 0 && (
                        <ul className="notesList">
                          {fundingNoteslist.map((note) => (
                            <li key={note.id}>{note.notes}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          
        )}
          <p className='tabs-note'>​¹For FHA, VA and Non-Conforming loans on certain occasions, some conditions require manual review. These conditions will not be reviewed on demand, but they will be reviewed within 1 business day.<br/>
          ²Any file submitted before 10 AM PT will be funded the same day.</p> 
        
      </Container>
    </StyleWrapper>
  );
};

export default memo(TurnTimes);