/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useState, useEffect, Suspense, lazy } from "react";
import { Container, Row, Col, Button, Image, Card } from "react-bootstrap";
import "./BodySection.css";


export default function BodySection() {
    const imgCDNURL = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE;

    const handleScrollToContact = () => {
        const contactSection = document.getElementById("webinarForm");
        if (contactSection) {
          const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 0; // Adjust based on your navbar's class or tag
          const contactSectionPosition = contactSection.offsetTop - navbarHeight; // Calculate the position minus the navbar height
    
          window.scrollTo({
            top: contactSectionPosition,
            behavior: "smooth",
          });
        }
      };
    const contentArray = [
        { id: 1, title: 'Free Lead Prospecting', description: 'Use the power of AskSimpers.Ai\'s team of bilingual human prospectors to call leads on your behalf and convert them right back to you.', image: 'magnet.webp' },
        { id: 2, title: 'Listing Website Creation', description: 'Have AskSimpers.Ai  create a Search Engine Optimization (SEO)-Boosted website for your listing and get leads directed to you.', image: 'monitor.webp' },
        { id: 3, title: '100% Trusted Warranty', description: 'AskSimpers.Ai\'s responses are backed by Mortgage Possible*, so if AskSimpers.Ai says it, Mortgage Possible will back it.', image: 'star-badge.webp' }
    ];
    return (
        <div className="body-section">
            <Container>
                <Row className="justify-content-center mt-5">
                    {contentArray.map(content => (
                        <Col key={content.id} md={4} className="mb-4">
                            <div className="gradient-border">
                                <Card className="quality-cards">
                                    <Card.Body>
                                        <img src={imgCDNURL + 'Images/' + content.image} alt={content.title} className="card-images" />
                                        <Card.Title className="card-title">{content.title}</Card.Title>
                                        <Card.Text>{content.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container>
                <Row className="mobile-column-reverse">
                    <div className="brown-line"></div>
                    <Col lg={5} className="learn-more-things">
                        <p>Learn about <a href='https://www.swmc.com/angelai/justin'target="_blank" rel="noreferrer">AskSimpers.Ai</a>  and  the capabilities she has. Discover how to access <a href='https://www.swmc.com/angelai/justin'target="_blank" rel="noreferrer">AskSimpers.Ai</a>, create your  account, and start providing your real time scenarios and questions.</p>
                        <Button className="learn-more-things-button" onClick={handleScrollToContact}>Schedule a Demo</Button>
                    </Col>
                    <Col lg={7}>
                        <div className="mobile-note-section">
                       <a href='https://www.swmc.com/angelai/justin'target="_blank" rel="noreferrer" className="mobile-link"><img src={imgCDNURL+"assets/asksimpers-ai-mobile.webp"}alt="AskSimpers.Ai" width="374px" height="681px" loading="lazy" className="img-fluid ask-simpers-mobile" /></a>
                            <div className="mob-flex">
                                <div className="notes"><div className="note-image" style={{
                                    backgroundColor: '#FFE0D8'
                                }}> <img src= {imgCDNURL + "Images/note2.webp"} alt="Image for MindBlowing" /></div><div className="note-content" style={{
                                    backgroundColor: '#8C412D'
                                }}><p className="note-heading-mob mobile-align">MindBlowing</p><p>Ai Tech You NEED to See</p></div>
                                </div>
                                <div className="notes"><div className="note-image" style={{
                                    backgroundColor: '#FFEEE0'
                                }}> <img src= {imgCDNURL + "Images/note1.webp"} alt="Image for Incredible point" /></div><div className="note-content" style={{
                                    backgroundColor: '#D37327'
                                }}><p className="note-heading-mob">Incredible</p><p>Speed and Accuracy for every scenario</p></div>
                                </div>
                                <div className="notes"><div className="note-image" style={{
                                    backgroundColor: '#FFF5E9'
                                }}> <img src= {imgCDNURL + "Images/note3.webp"} alt="AskSimpers.Ai" /></div><div className="note-content" style={{
                                    backgroundColor: '#F69B2F'
                                }}><p className="note-heading-mob">24/7</p><a href='https://www.swmc.com/angelai/justin'target="_blank" rel="noreferrer">AskSimpers.Ai</a>  runs continuously<p></p></div>
                                </div>
                            </div>
                        </div>
                    </Col>
                   
                </Row>
               
            </Container>
            
        </div>
    );
}