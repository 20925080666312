import React, { useEffect, useState, lazy, Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from "../Loading";
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import TurnTimesCreator from '../TurnTimesCreator/index';
import TurnTimesCreatorSecond from '../TurnTimesCreatorB/index';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import "./TurnTimes.css";



const TableWrapper = styled.div`
  .table-responsive > .table-bordered {
    border: 1px solid #dee2e6 !important;
    width: 200% !important;
  }
  .table-bordered > :not(caption) > * {
    border-width: 1px 0 !important;
    border-color: #dee2e6 !important;
  }
`;
const PhoneRs = lazy(() => import("./PhoneResponseTimes/PhoneRs"));

export function TurnTimes({ location }) {
  const [turnData, setTurnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('Conventional'); // Default active tab

  useEffect(() => {
    const fetchTurnData = async () => {
      try {
        const response = await axios.get(
          'https://d2w24n4g34usfg.cloudfront.net/swmc/jsons/turn_times.json'
        );
        setTurnData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching turn data:', error);
        setLoading(false);
      }
    };

    fetchTurnData();
  }, []);

  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <>
    <div className='turn-time-section'>
    <div className='turn-time-commitment-banner'>
    <Container>
    <div className="hero_image turn-time-commitment-image">
            <div className="turnheading">
              <h1 className='text-center'>Turn Time Commitment</h1>
            </div>
          </div>
    </Container>
    </div>
      <Container>
        <div>
         
          <Container fluid className="pageContainer">
            <div>
              <Row>
                <Col md={12}>
                  <div className="header_border"></div>
                  <h2 style={{ marginBottom: '20px' }} className='text-center mt-3 mb-3'>Turn Time Commitment</h2>

                  {loading ? (
                    <p>loading...</p>
                  ) : (
                    <React.Fragment>
                      <Tabs
                        id="program-tabs"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="mb-3"
                      >
                        <Tab eventKey="Conventional" title="Conventional">
                          <TurnTimesCreator
                            programName="Conventional"
                            timesJson={turnData}
                          />
                        </Tab>
                        <Tab eventKey="FHA" title="FHA">
                          <TurnTimesCreator
                            programName="FHA"
                            timesJson={turnData}
                          />
                        </Tab>
                        <Tab eventKey="VA" title="VA">
                          <TurnTimesCreator
                            programName="VA"
                            timesJson={turnData}
                          />
                        </Tab>
                        <Tab eventKey="USDA" title="USDA">
                          <TurnTimesCreator
                            programName="USDA"
                            timesJson={turnData}
                          />
                        </Tab>
                        <Tab eventKey="HECM" title="HECM">
                          <TurnTimesCreatorSecond
                            programName="HECM"
                            timesJson={turnData}
                          />
                        </Tab>
                        <Tab eventKey="Non Conforming" title="Non Conforming">
                          <TurnTimesCreatorSecond
                            programName="Non Conforming"
                            timesJson={turnData}
                          />
                        </Tab>
                      </Tabs>                    

                      <center className='cut-off-link'>
                        <Link to="/cutoff-dates" target='_blank'>
                          Cutoff Dates for Funding Loans in {monthArray[month]}{' '}
                          {year}
                        </Link>
                      </center>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </div>
          </Container>

        </div>
      </Container>
      <Suspense fallback={<Loading />}>
        <PhoneRs />
      </Suspense>
      </div>
    </>
  );
}

export default TurnTimes;