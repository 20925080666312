import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { ASKSIMPERS_AI_REDIRECT_URL, isMobileOrTablet, isDBAApprovedState } from "../../../../Utils";
import { Link } from 'react-router-dom';
import UserWayAccessibilityMenu from "../../../../UserWayAccessibilityMenu/index";
import "./NavigationBar.css";

export default function NavigationBar() {
    const [navbar, setNavbar] = useState(false);
    const isMobileOrTabletDevice = isMobileOrTablet();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isApprovedState, setIsApprovedState] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const imgCDNURL = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE;
   
    const openLoginModal = () => {
        const modalTop = window.pageYOffset + 20; // Adjust this value as needed
        // Show the modal
        setShowLoginModal(true);
    };

    let prevScrollPos = window.scrollY;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dbaApproved = await isDBAApprovedState();
                setIsApprovedState(dbaApproved);
            } catch (error) {
                // Handle error
                console.error("Error fetching data:", error);
                setIsApprovedState(false); // Set state accordingly
            }
        };

        fetchData();
    }, []);

    useEffect(async () => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const menuNavbar = document.getElementById("menuNavbar");
        if (currentScrollPos === 0) {
            menuNavbar.classList.remove("navbarTransition");
            setNavbar(false);
        } else {
            if (prevScrollPos > currentScrollPos) {
                setNavbar(true);
            } else if (prevScrollPos < currentScrollPos) {
                if (!isMobileOrTabletDevice) {
                    setNavbar(true);
                } else {
                    setNavbar(false);
                    menuNavbar.classList.add("navbarTransition");
                }
            }
        }
        prevScrollPos = currentScrollPos;
    };

    const handleMenuClick = () => {
        setExpanded(false);
        setNavbar(false);
    };
    const handleNoButtonClick = () => {
        window.open(
            'https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/',
            '_blank',
        );
        closeLoginModal();
    };
    const closeLoginModal = () => {
        setShowLoginModal(false);
    };
    const handleYesButtonClick = () => {
        window.open(
            'https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/',
            '_blank',
        );
        closeLoginModal();
    };

    return (
        <>
            <div>
                <Navbar
                    id="menuNavbar"
                    collapseOnSelect
                    expanded={expanded}
                    onToggle={() => setExpanded(!expanded)}
                    onSelect={() => setExpanded(false)}
                    expand="lg"
                    fixed="top"
                    bg="dark"
                    variant="dark"
                    className={navbar ? "navbar active" : "navbar"}
                >
                    <Container className="navbar-container">
                        <Navbar.Brand className="navbrand-logo">
                            <div className="d-flex">
                                <div>
                                    <Link to="/" onClick={() => setExpanded(false)}>
                                        {!isApprovedState ? <img
                                            src={imgCDNURL + "assets/mortgage-possible-dba-not-approved-white.webp"}
                                            alt="MortgagePossible logo"
                                            width="494px"
                                            height="184px"
                                            className="main_logo img-fluid"
                                        /> : <img
                                            src={imgCDNURL + "assets/mortgage-possible-dba-approved-white.webp"}
                                            alt="MortgagePossible logo"
                                            width="494px"
                                            height="184px"
                                            className="main_logo img-fluid"
                                        />}

                                    </Link>
                                </div>
                              
                            </div>
                        </Navbar.Brand>
                       
                    </Container>
                    <div className="desktop-AccessibilityMenu-show">
            <UserWayAccessibilityMenu color="white" width={40} height={40}/>
          </div>
          </Navbar>
          <div className="mobile-AccessibilityMenu-show">
        <UserWayAccessibilityMenu color="white" width={40} height={40}/>
       </div>
              
            </div>
        </>
    );
}
