/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

const axios = require("axios");

const DIVISION_OF_SUN_WEST = "A Division of Sun West Mortgage Company, Inc.";
const ASKSIMPERS_AI_REDIRECT_URL = "https://asksimpers.ai";
const LOAN_PRODUCTS_LINK_PATH = process.env.REACT_APP_SWMC_PRODUCTBASE_URL;
const TERMS_AND_CONDITIONS_LINK_PATH = "/terms-and-conditions";
const IMG_CDN_RESOURCE = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + "assets/";
const MORTGAGE_POSSIBLE_INFO_JSON = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + "jsons/mortgagepossible-info.json";
const MORTGAGE_POSSIBLE_FAQ_JSON = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + "jsons/mortgage_possible_faq_page.json";

/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string} inputString - The string to capitalize.
 * @return {string} The modified string with the first letter of each word capitalized.
 */
function capitalizeFirstLetter(inputString) {
  if (!inputString || typeof inputString !== 'string') {
    return '';
  }

  // Convert the entire string to lowercase
  const lowercaseString = inputString.toLowerCase();

  // Split the string into words using spaces
  const words = lowercaseString.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Concatenate the modified words to form the final string
  return capitalizedWords.join(' ');
}

/**
 * @author Pratik Kajrolkar 
 * @date 07/20/2023
 * 
 * Formats a phone number by removing non-digit characters and converting it to the desired format.
 * @param {String} number - The phone number to be formatted.
 * @return {string} The formatted phone number in the format (XXX) XXX-XXXX, or the original input if 
 * the number does not match the expected format.
 */
function formatPhoneNumber(number) {
  // Remove all non-digit characters
  const cleaned = ('' + number).replace(/\D/g, '');

  // Extract the different parts of the phone number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Format the phone number in the desired format
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  // If the number does not match the expected format, return the original input
  return number;
}

/**
 * @author Pratik Kajrolkar 
 * @date 07/20/2023
 * 
 * Adds the international code to a phone number if it does not already have it.
 * @param {String} number - The phone number to add the international code to.
 * @param {String} countryCode - The international country code.
 * @return {String} - The phone number with the international code added.
 */
function addInternationalCode(number, countryCode) {
  // Remove all non-digit characters
  const cleanedNumber = ('' + number).replace(/\D/g, '');

  // Check if the cleaned number already starts with the country code
  if (cleanedNumber.startsWith(countryCode)) {
    return cleanedNumber;
  }

  // Add the country code to the number
  return countryCode + cleanedNumber;
}

/**
 * @author Pratik Kajrolkar 
 * @date 08/04/2023
 * 
 * Determines whether the device accessing the page is a mobile
 *
 * @return {String} Returns "Mobile" if the device is a mobile
 */
function isMobileDevice() {
  const width = window.innerWidth;
  const isMobile = width < 768; // Adjust the values as needed

  if (isMobile) {
    return true;
  } else {
    return false;
  }
}

/**
 * @author Pratik Kajrolkar 
 * @date 08/04/2023
 * 
 * Determines whether the device accessing the page is a mobile, tablet, or desktop.
 *
 * @return {String} Returns "Mobile" if the device is a mobile, "Tablet" if the device is a tablet, or "Desktop" if the device is a desktop.
 */
function isMobileOrTablet() {
  const width = window.innerWidth;
  const isMobile = width < 768
  const isTablet = width >= 768 && width <= 991; // Adjust the values as needed

  if (isMobile) {
    return true;
  } else if (isTablet) {
    return true;
  } else {
    return false;
  }
}

function isHighResolutionScreen() {
  const width = window.innerWidth;
  const isHighResolutionScreen = width > 1440;
  return isHighResolutionScreen;
}
function isUltraHighResolutionScreen() {
  const width = window.innerWidth;
  const isUltraHighResolutionScreen = width > 3000;
  return isUltraHighResolutionScreen;
}

/**
 * Decodes HTML entities in a given input string.
 *
 * @param {string} input - The input string to decode.
 * @return {string} The decoded string.
 */
function htmlDecode(input) {
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

/**
 * Converts HTML entities in a given input string to their corresponding characters.
 *
 * @param {string} text - The input text containing HTML entities.
 * @return {string} The text with HTML entities decoded.
 */
function convertHTMLEntity(text) {
  const span = document.createElement('span');
  return text
    .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      span.innerHTML = entity;
      return span.innerText;
    });
}

/**
 * Replaces all occurrences of "Sun West Mortgage Company, Inc." and related variations with "Mortgage Possible" 
 * in the given data.
 *
 * @param {string} data - The input data to be modified.
 * @return {string} The modified data with the replacements made.
 */
function replaceSunWestInfoWithMortgagePossibleInfo(data) {
  let replaceData = data.replace("Sun West Mortgage USA, Inc.", "Mortgage Possible");
  replaceData = replaceData.replace("Sun West Mortgage USA Company, Inc.", "Mortgage Possible");
  replaceData = replaceData.replace(/Sun West Mortgage Company, Inc\.?/g, "Mortgage Possible");
  replaceData = replaceData.replace(/Sun West Mortgage Company, Inc/g, "Mortgage Possible");
  replaceData = replaceData.replace(/Sun West Mortgage/g, "Mortgage Possible");
  replaceData = replaceData.replace(/Sun West/g, "Mortgage Possible");
  

  return replaceData;
}

/**
 * Replaces all occurrences of "Sun West Mortgage Company, Inc." and related variations with 
 * "Mortgage Possible, A Division of Sun West Mortgage Company, Inc." in the given data.
 *
 * @param {string} data - The input data to be modified.
 * @return {string} The modified data with the replacements made.
 */
function replaceSunWestInfoWithMortgagePossibleDivisionInfo(data) {
  let replaceData = data.replace(/Sun West Mortgage Company, Inc\.?/g, "Mortgage Possible, " + DIVISION_OF_SUN_WEST);
  replaceData = replaceData.replace("Sun West Mortgage USA, Inc.", "Mortgage Possible, " + DIVISION_OF_SUN_WEST);

  return replaceData;
}

/**
 * Retrieves mortgage possible information from the server.
 *
 * @return {Promise<Object|null>} The mortgage possible information as an object, or null if an error occurred.
 */
async function getMortgagePossibleInfo() {
  try {
    const response = await axios.get(MORTGAGE_POSSIBLE_INFO_JSON);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error("Unable to fetch mortgage possible information: ", error);
  }

  return null;
}

async function getDBANotApprovedStateList() {
  try {
    const response = await getMortgagePossibleInfo();
    if (response) {
      const mortgagepossibleStatesNotRegistered = response.mortgagepossibleStatesNotRegistered;
      return mortgagepossibleStatesNotRegistered;
    }
  } catch (error) {
    console.error("Error occurred:", error);
  }

  return null;
}

/**
 * Checks if the database state is approved.
 *
 * @return {boolean} Returns true if the database state is approved, false otherwise.
 */
async function isDBAApprovedState() {
  try {
    const response = await getMortgagePossibleInfo();
    if (response) {
      const mortgagepossibleStatesNotRegistered = response.mortgagepossibleStatesNotRegistered;
      const maxmindResponse = await axios.post(process.env.REACT_APP_MAXMIND, {}, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (
        maxmindResponse.data !== "IP Not Available" &&
        maxmindResponse.data !== "Invalid Country"
      ) {
        const zipCode = maxmindResponse.data.zipcode;
        if (zipCode) {
          const zipcodeResponse = await axios.get(process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + zipCode);
          const state = zipcodeResponse.data.state;
          if (mortgagepossibleStatesNotRegistered.includes(state)) {
            return false;
          }
        }
      }
    }
  } catch (error) {
    console.error("Error occurred:", error);
  }

  return true;
}

/**
 * Retrieves the contact us data from the mortgage possible info.
 *
 * @return {Promise<Object|null>} The contact us data as an object, or null if an error occurred.
 */
async function getContactUsData() {
  try {
    const response = await getMortgagePossibleInfo();
    if (response) {
      return response.contactUs;
    }
  } catch (error) {
    console.error("Error occurred while fetching contact us data:", error);
    
  }

  return null;
}

/**
 * @author Pratik Kajrolkar
 * @date 07/20/2023
 *
 * The below code will exposed the variable and functions written
 * under this javascript file to other javascript files when
 * this file is object is created under them.
 */
module.exports = {
  DIVISION_OF_SUN_WEST,
  ASKSIMPERS_AI_REDIRECT_URL,
  LOAN_PRODUCTS_LINK_PATH,
  TERMS_AND_CONDITIONS_LINK_PATH,
  IMG_CDN_RESOURCE,
  MORTGAGE_POSSIBLE_INFO_JSON,
  MORTGAGE_POSSIBLE_FAQ_JSON,
  capitalizeFirstLetter,
  formatPhoneNumber,
  addInternationalCode,
  isMobileDevice,
  isMobileOrTablet,
  isHighResolutionScreen,
  isUltraHighResolutionScreen,
  htmlDecode,
  convertHTMLEntity,
  replaceSunWestInfoWithMortgagePossibleInfo,
  replaceSunWestInfoWithMortgagePossibleDivisionInfo,
  getMortgagePossibleInfo,
  getContactUsData,
  getDBANotApprovedStateList,
  isDBAApprovedState
}
