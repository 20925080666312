import React from 'react';
import "./brokerpricing.css";

const brokerPricing = process.env.REACT_APP_WHOLESALE_PRICING;

const MyIframeComponent = () => (
  <div>
      <title>
        Mortgage Possible: Your Way to Great Rates and Hassle-Free Mortgages
      </title>
    <iframe
      className="iframebox"
      title="Wholesale Pricing Development"
      width="100%"
      // height="600px"
      src={brokerPricing}
      frameBorder="0"
      allowFullScreen
      // scrolling="no"
    />
  </div>
);

export default MyIframeComponent;
