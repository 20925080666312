/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useState, useEffect, Suspense, lazy } from "react";
import { Container, Row, Col } from "react-bootstrap";
import JSONData from "../fallback/jsons/mp-loan-products.json";
import axios from "axios";
import Helmet from "react-helmet";
import { useLocation } from 'react-router-dom';
import { isMobileDevice, isMobileOrTablet, isHighResolutionScreen, isDBAApprovedState } from "../Utils";
import Banner from "../Banner";
import Loading from "../Loading";
import "./LoanProducts.css";

function LoanProducts() {
    const [isMobile, setIsMobile] = useState(isMobileDevice());
    const [isHighScreenDisplay, setIsHighScreenDisplay] = useState(isHighResolutionScreen());
    const [productsData, setProductsData] = useState([]);
    const [isApprovedState, setIsApprovedState] = useState(true);

    const location = useLocation();


    useEffect(() => {
        function handleResize() {
            setIsMobile(isMobileDevice());
            setIsHighScreenDisplay(isHighResolutionScreen());
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dbaApproved = await isDBAApprovedState();
                setIsApprovedState(dbaApproved);
            } catch (error) {
                // Handle error
                console.error("Error fetching data:", error);
                setIsApprovedState(false); // Set state accordingly
            }
        };

        fetchData();
    }, [isApprovedState]);


    useEffect(() => {
        const fetchProductsData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + "jsons/mp-loan-products.json");

                if (response.data && Object.keys(response.data).length > 0) {
                    setProductsData(response.data);
                    // console.log(response.data);
                } else {
                    //console.log("CDN JSON is empty, falling back to local JSON.");
                    setProductsData(JSONData);
                    // console.log("local", productsData);
                }
            } catch (error) {
                console.log("Error fetching from CDN:", error);
                setProductsData(JSONData);
            }
        };

        fetchProductsData();
    }, []);

    useEffect(() => {
        const scrollToPageSection = () => {
            const hash = location.hash.substring(1); // Remove '#' from hash

            // Check if the hash exists in any of the IDs fetched from JSON
            const idExists = productsData.some(column =>
                Object.values(column).some(section =>
                    section.Lists ? section.Lists.some(list => list.id === hash) : false
                )
            );

            if (idExists) {
                setTimeout(() => {
                    const PageSection = document.getElementById(hash);

                    if (PageSection) {
                        const yOffset = -70; // Adjust this value as needed
                        const y = PageSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                }, 500); // Adjust the delay time as needed
            } else {
                setTimeout(() => {
                    //scroll to first product section
                    const PageSection = document.getElementById("nonQM");

                    if (PageSection) {
                        const yOffset = -70; // Adjust this value as needed
                        const y = PageSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                }, 500);
            }
        };
        scrollToPageSection();
    }, [location, productsData]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    return (
        <>        <div>
            <Helmet>
                <title>
                    Mortgage Possible Loan Products: Your Way to Great Rates and Hassle-Free Mortgages
                </title>
                <link rel="canonical" href={process.env.PUBLIC_URL + "loan-products"} />

                <meta property="og:url" content={process.env.PUBLIC_URL + "loan-products"} />
                <meta property="og:type" content="website" />
                <meta
                    name="title"
                    property="og:title"
                    content="Mortgage Possible Loan Products: Your Way to Great Rates and Hassle-Free Mortgages"
                />
            </Helmet>
        </div>

            <div>
                <Banner
                    isMobile={isMobile}
                    isHighScreenDisplay={isHighScreenDisplay}
                    showLearnMoreBtn={true}
                    isApprovedState={isApprovedState}
                />
                <Suspense fallback={<Loading />}>
                    <div>
                        <div className="mobile-container-products">
                            <Container className="products-container mb-5">
                                <div className="gradient-1" />
                                <Row className="products-row">
                                    {productsData.map((column, index) => (
                                        <Col key={index} className="products-col" sm={12} md={6} lg={4} xl={3}>
                                            {Object.keys(column).map((key) => {
                                                const section = column[key];
                                                return (
                                                    <div key={key}>
                                                        {section.Lists ? (
                                                            // Check if there are multiple lists in the column
                                                            section.Lists.map((list, listIndex) => (
                                                                <div key={listIndex}>
                                                                    <div className="d-flex align-items-center pb-2 heading-border">

                                                                        <h5 className="products-section-head" id={list.id}>
                                                                            {list.heading}
                                                                        </h5>
                                                                    </div>
                                                                    <ul className="custom-list">
                                                                        {list.List.map((item) => (
                                                                            <li key={item.id}>

                                                                                <a href={item.url} target="_blank" rel="noreferrer">
                                                                                    {item.name}
                                                                                </a>

                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            // If there's only one list
                                                            <ul className="custom-list">
                                                                <div className="d-flex align-items-center pb-2 heading-border">
                                                                    <div className="orange-head-box"></div>
                                                                    <h5 className="products-section-head">
                                                                        {section.heading}
                                                                    </h5>
                                                                </div>
                                                                {section.List.map((item) => (
                                                                    <li key={item.id}>
                                                                        <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </Col>
                                    ))}

                                </Row>
                                <div className="gradient-2" />
                            </Container>
                        </div>
                    </div>
                </Suspense>
            </div>
        </>
    )
}
export default LoanProducts
