import React, { Suspense, lazy } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import NavigationBar from "../NavigationBar/NavigationBar";

const Footer = lazy(() => import("../Footer"));

const Layout = ({ children }) => {
    const location = useLocation();
    const hideFooterRoutes = ["/", "/home", "/supercharge", "/supercharge/"];
    const hideHeaderRoutes = ["/supercharge"];

    return (
        <Container
            fluid
            className="container-bg ps-0 pe-0"
        >
              {!hideHeaderRoutes.includes(location.pathname) && (
            <NavigationBar /> )}
                        {children}
            {!hideFooterRoutes.includes(location.pathname) && (
                <Suspense fallback={<div />}>
                    <Footer />
                </Suspense>
            )}
        </Container>
    );
};

export default Layout;
