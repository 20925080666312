/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useState, useEffect, Suspense, lazy } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import parser from "html-react-parser";
import { IMG_CDN_RESOURCE } from "../Utils";
import { Link } from 'react-router-dom';
import "./index.css";
const Widgets = lazy(() => import("../widgets/Widget"));
const BecomePartnerFormModal = lazy(() => import("../PartnerWithUs/BecomePartnerFormModal"));

function Banner({
    isMobile,
    isHighScreenDisplay,
    showLearnMoreBtn,
    isApprovedState
}) {
    const [showLearnMoreButton, setShowLearMoreButton] = useState(showLearnMoreBtn);
    const [showModal, setShowModal] = useState(false);
    const [imgSrc, setImgSrc] = useState("");

    const handleLearnMoreClick = () => {
        setShowModal(true);
    };
    const handleCheckPricingClick = () => {
        window.open('/tpo/priceengine', '_blank');
    };

    useEffect(() => {
        setImgSrc(isMobile ? IMG_CDN_RESOURCE + "mobile-bg.webp" : IMG_CDN_RESOURCE + "desktop-bg.webp");
    }, [isMobile]);

    return (
        <div>
            <div className="bannerBackground">
                <div className={isMobile || isHighScreenDisplay ? "position-relative container" : "position-relative container-fluid ms-desktop-3"}>
                    <div>
                        <Image
                            src={imgSrc}
                            width={isMobile ? "375" : "1280"}
                            height={isMobile ? "592" : "707"}
                            alt="Banner Background"
                            className="bannerBackgroundImage"
                             loading="eager"
                        />
                    </div>
                    <div>
                        <Container className="position-absolute top-0">
                            <Row>
                                <Col sm={12} md={6} lg={6} xl={6} className="mb-3">
                                    <h1 className="bannerHeading">
                                        {isMobile ?
                                            parser("Making the<br/>Impossible<br/>Mortgage Possible")
                                            :
                                            parser("Making the<br/>Impossible Mortgage Possible")
                                        }
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6} lg={6} xl={6} className="mb-3">
                                    <p className="bannerHeadingTagLine">Revolutionary Innovative Tradition Partnership</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6} lg={6} xl={6} className="mb-3">
                                    <Link to="/tpo/priceengine" target="_blank" rel="noopener noreferrer">
                                        <Button variant="light" className="bannerLearnMoreBtn">
                                            Check Pricing
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                            {/* {showLearnMoreButton && (
                                <Row>
                                    <Col sm={12} md={6} lg={6} xl={6} className="mb-3">
                                        <Button variant="light" className="bannerLearnMoreBtn" onClick={handleLearnMoreClick}>
                                            LEARN MORE
                                        </Button>
                                    </Col>
                                </Row>
                            )} */}
                        </Container>
                        <div className="position-absolute bottom-0 right-0">
                            <div className="bannerBenefitsContainer">
                                <div className="approvalOnDemand">
                                    <Image
                                        src={IMG_CDN_RESOURCE + "approval-on-demand-benefits-icon.webp"}
                                        alt="Approval on Demand"
                                        width="40"
                                        height="44"
                                        className="img-fluid approvalOnDemandIcon mb-3"
                                        loading="eager"
                                    />
                                    <p>Approval on Demand</p>
                                </div>
                                <div className="noAgencyOverlays">
                                    <Image
                                        src={IMG_CDN_RESOURCE + "no-agency-overlays-benefits-icon.webp"}
                                        alt="No Agency Overlays"
                                        width="40"
                                        height="44"
                                        className="img-fluid noAgencyOverlaysIcon mb-3"
                                        loading="eager"
                                    />
                                    <p>No Agency Overlays</p>
                                </div>
                                <div className="fairlending">
                                    <Image
                                        src={IMG_CDN_RESOURCE + "fairlending-benefits-icon.webp"}
                                        alt="Fair Lending"
                                        width="40"
                                        height="44"
                                        className="img-fluid fairlendingIcon mb-3"
                                        loading="eager"
                                    />
                                    <p>Fair Lending</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={<div />}>
                <BecomePartnerFormModal
                    heading="Learn More"
                    showModal={showModal}
                    setShowModal={setShowModal}
                    isApprovedState={isApprovedState}
                />
            </Suspense>
            <Suspense fallback={<div/>}>
      <Widgets />
    </Suspense>
        </div>
    );
}

export default Banner;