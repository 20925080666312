/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-lonely-if */
/* eslint-disable radix */
/* eslint-disable camelcase */
import React from 'react';
import getPSTTimestamp from '../PST';

export const imagePath = (path) => {
  return `https://prod-cdn.swmc.com/swmc-common/images/wholesale-websites/${path}`;
};

export const thumbnailImagePath =
  'https://resources.swmc.com/swmc-images/thumbnails';

export const foreignNationalProgramName = 'Foreign National';
export const fha203kProgramName = 'FHA 203K';

function getDayName(day) {
  const arrayOfWeekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return arrayOfWeekdays[day];
}

export const businessDayDescriptionDisplay = hours_data => {
  const turn_time_hours = hours_data;
  let businessDayDescription = '';

  if (turn_time_hours === 0) {
    businessDayDescription = (
      <span>
        <img
          alt="Thoroughly Reviewed & Underwritten On Demand" 
          className="underWritten-on-demand"
          // src={`${process.env.REACT_APP_SWMC_IMG_CDN}HomeImages/underwriting-review-time.webp`}
          src='https://resources.swmc.com/swmc-images/HomeImages/underwriting-review-time.webp'
        />{' '}
      </span>
    );
  } else {
    // check dates multiple  of 24
    const hours_multiple_timesOf24 = turn_time_hours / 24;
    const dateTime = getPSTTimestamp(new Date());
    const dayNumber = dateTime.getDay();

    // Sunday ||   //Saturday    //Friday
    if (dayNumber === 0 || dayNumber === 6 || dayNumber === 5) {
      const currentHrs = dateTime.getHours();

      // Sunday
      if (dayNumber === 0 && currentHrs < 20) {
        // businessDayDescription = "By 8 AM Monday";
        businessDayDescription = (
          <span>
            <img
              alt="eight-hour-icon"
              className="eight-hour-icon"
              src={`${imagePath}/eight_am_approval.svg`}
            />{' '}
            Monday
          </span>
        );
      } else if (dayNumber === 0 && currentHrs > 20) {
        // businessDayDescription = "By 8 AM Tue";
        businessDayDescription = (
          <span>
            <img
              alt="eight-hour-icon"
              className="eight-hour-icon"
              src={`${imagePath}/eight_am_approval.svg`}
            />{' '}
            Tuesday
          </span>
        );
      } else {
        // businessDayDescription = "By 8 AM Monday";
        businessDayDescription = (
          <span>
            <img
              alt="eight-hour-icon"
              className="eight-hour-icon"
              src={`${imagePath}/eight_am_approval.svg`}
            />{' '}
            Monday
          </span>
        );
      }
    } else {
      // Monday to Thursday
      const currentHrs = dateTime.getHours();

      if (
        currentHrs === 6 ||
        currentHrs === 20 ||
        (currentHrs > 6 && currentHrs < 20)
      ) {
        // in  business hr  //(6AM-8PM) means (06:00:00 - 20:00:00)

        if (turn_time_hours < 24) {
          businessDayDescription = 'By 8 PM Today';
        } else {
          const dateTime2 = getPSTTimestamp(new Date());
          const whichDay = dateTime2.getDay();

          // Sunday ||   //Saturday    //Friday
          if (whichDay === 0 || whichDay === 6 || whichDay === 5) {
            // businessDayDescription = "By 8 AM Monday";
            businessDayDescription = (
              <span>
                <img
                  alt="eight-hour-icon"
                  className="eight-hour-icon"
                  src={`${imagePath}/eight_am_approval.svg`}
                />{' '}
                Monday
              </span>
            );
          } else if (hours_multiple_timesOf24 === 1) {
            // businessDayDescription = "By 8 AM Tomorrow";
            businessDayDescription = (
              <span>
                <img
                  alt="eight-hour-icon"
                  className="eight-hour-icon"
                  src={`${imagePath}/eight_am_approval.svg`}
                />{' '}
                Tomorrow
              </span>
            );
          } else {
            // businessDayDescription = "By 8 AM"+ ' '+ getDayName(whichDay);
            businessDayDescription = (
              <span>
                <img
                  alt="eight-hour-icon"
                  className="eight-hour-icon"
                  src={`${imagePath}/eight_am_approval.svg`}
                />{' '}
                {getDayName(whichDay)}
              </span>
            );
          }
        }
      } else {
        // after business hr (after 8PM) => (8PM -6AM) like 8 PM (means 20:00:00) to 6AM (means 06:00:00) morning
        // we needs to add one more day directly as per logic recommended for after business hours
        if (turn_time_hours < 24) {
          const dateTime2 = getPSTTimestamp(new Date());
          const newDateWithTomorrow = dateTime2.setDate(dateTime2.getDate());
          const latestDate = new Date(newDateWithTomorrow);

          const whichDay = latestDate.getDay();
          // Sunday ||   //Saturday    //Friday
          if (whichDay === 0 || whichDay === 6 || whichDay === 5) {
            // businessDayDescription = "By 8 AM Monday";
            businessDayDescription = (
              <span>
                <img
                  alt="eight-hour-icon"
                  className="eight-hour-icon"
                  src={`${imagePath}/eight_am_approval.svg`}
                />{' '}
                Monday
              </span>
            );
          } else {
            // businessDayDescription = "By 8 AM Tomorrow";
            businessDayDescription = (
              <span>
                <img
                  alt="eight-hour-icon"
                  className="eight-hour-icon"
                  src={`${imagePath}/eight_am_approval.svg`}
                />{' '}
                Tomorrow
              </span>
            );
          }
        } else {
          const dateTime2 = getPSTTimestamp(new Date());

          let whichDay = '';
          if (currentHrs > 20) {
            whichDay = dateTime2.getDay() + hours_multiple_timesOf24;
          } else {
            whichDay = dateTime2.getDay();
          }

          // Sunday ||   //Saturday    //Friday
          if (whichDay === 0 || whichDay === 6 || whichDay === 5) {
            // businessDayDescription = "By 8 AM Monday";
            businessDayDescription = (
              <span>
                <img
                  alt="eight-hour-icon"
                  className="eight-hour-icon"
                  src={`${imagePath}/eight_am_approval.svg`}
                />{' '}
                Monday
              </span>
            );
          } else {
            // businessDayDescription = "By 8 AM" + ' ' + getDayName(whichDay);
            businessDayDescription = (
              <span>
                <img
                  alt="eight-hour-icon"
                  className="eight-hour-icon"
                  src={`${imagePath}/eight_am_approval.svg`}
                />{' '}
                {getDayName(whichDay + 1)}
              </span>
            );
          }
        }
      }
    }
  }
  return businessDayDescription;
};

export const getInterestRatesDateTextToShow = createdAt => {
  let interestRatesDateText = '';
  if (createdAt) {
    const createdAtDate = createdAt;
    const formatDate = format(parseISO(createdAtDate), 'MM/dd/yyyy');
    const formatTimeDisplay = format(parseISO(createdAtDate), 'hh:mm a');

    // Create date from input value
    const inputDate = new Date(formatDate);

    // Get today's date
    const todaysDate = new Date();

    // checking yesterday's date
    const yesterday = new Date(todaysDate);

    const yesterdaysDate = new Date(yesterday.setDate(yesterday.getDate() - 1));

    // call setHours to take the time out of the comparison
    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      // Date equals today's date
      interestRatesDateText = `Interest Rates as of ${formatTimeDisplay.toLowerCase()} PST Today`;
    } else if (
      inputDate.setHours(0, 0, 0, 0) === yesterdaysDate.setHours(0, 0, 0, 0)
    ) {
      // Date equals yesterday's date
      interestRatesDateText = `Interest Rates as of ${formatTimeDisplay.toLowerCase()} PST Yesterday`;
    } else {
      interestRatesDateText = `Interest Rates as of ${formatTimeDisplay.toLowerCase()} PST ${formatDate}`;
    }
  } else {
    // Date is undefined (not available )
    interestRatesDateText = `Interest Rates as of`;
  }
  return interestRatesDateText;
  // setShowInterestRatesDateText(interestRatesDateText);
};

export const descriptionToDisplay = turnTimeProductData => {
  const turnTimeProductDataDetails = turnTimeProductData;
  let turn_time_description_ToShow = '';
  if (turnTimeProductDataDetails.turn_time_hours) {
    if (turnTimeProductDataDetails.turn_time_hours !== '') {
      turn_time_description_ToShow = businessDayDescriptionDisplay(
        turnTimeProductDataDetails.turn_time_hours,
      );
    } else {
      turn_time_description_ToShow =
        turnTimeProductDataDetails.turn_time_description;
    }
  } else {
    turn_time_description_ToShow =
      turnTimeProductDataDetails.turn_time_description;
  }

  return turn_time_description_ToShow;
};

export const ourHistoryVideoIdArray = [
  {
    id: 'sm1LuN4ECv8',
  },
];

export const videoIdArray = [
  {
    id: '1GWwNGS4yHE',
  },
  {
    id: '57FOWya_JQk',
  },
  {
    id: 'aP5eftWIKqw',
  },
  {
    id: 'ksVk_Yjf3d8',
  },
  {
    id: 'OCyyoS2zpo0',
  },
  {
    id: 'PC5PYW0yGmI',
  },
  {
    id: 'ssKGJmYQChE',
  },
  {
    id: 'vXR9-TvEQ-I',
  },
  {
    id: 'ylVO_d8ZCJc',
  },
  {
    id: 'YxyMt2uHgZE',
  },
];

export const generateYtThumbnail = idPassed =>
  `${thumbnailImagePath}/${idPassed}.jpg`;

export const generateYtVideoLink = idPassed =>
  `https://www.youtube.com/watch?v=${idPassed}&authuser=0`;

export const dragElement = (elmnt, holderEle) => {
  // (myDIv, myDiveader is holding element for this for this)
  // console.log('DragFn:', elmnt)
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  if (document.getElementById(`${elmnt.id}header`)) {
    /* if present, the header is where you move the DIV from: */
    document.getElementById(`${elmnt.id}header`).onmousedown = dragMouseDown;
  } else if (holderEle && holderEle.length > 0) {
    /* if present, the header is where you move the DIV from: */
    holderEle[0].onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV: */
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
    elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
  }

  function closeDragElement() {
    /* stop moving when mouse button is released: */
    document.onmouseup = null;
    document.onmousemove = null;
  }
};
