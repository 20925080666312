import React, { useEffect, useState } from 'react';
import { isDBAApprovedState } from "../Utils";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import HTMLReactParser from 'html-react-parser';
import styled from 'styled-components';
import './ApprovedUnderwriters.css';

const TableWrapper = styled.div`
  .table-bordered>:not(caption)>* {
    border-width: 1px 0 !important;
  }
  .table-bordered thead td, .table-bordered thead th {
    min-width: 250px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  th {
    background: rgba(143, 62, 26, 1)!important;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    border: 1px solid white;
    border-top: 0px !important;
    padding: .60rem;
  }
  td {
    padding: .50rem;
    border: 1px solid #efefef;
    font-size: 15px;
    background: white;
    text-align: left;
  }
  .table-responsive > .table-bordered {
    border: 0;
    font-size: 15px;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
    vertical-align: middle;
  }
  .table-striped tbody tr:nth-of-type(even) {
   background-color: #ffefe8;
   vertical-align: middle;
  }
  @media(min-width:768px) and (max-width:1050px){
    .table{
      width:100% !important;
    }
    .px-5{
      padding: 0px !important;
    }
  }
`;

const ContainerWrapper = styled.div`
  @media(min-width:768px) and (max-width:1050px){
    .px-5{
      padding-left: 20px !important;
      padding-right:20px !important;
    }
  }
`;

const replaceTerms = (data) => {
  const str = JSON.stringify(data);
  const replacedStr = str.replace(/SWMC|Sun West|sunwest/gi, 'Mortgage Possible');
  return JSON.parse(replacedStr);
};

const ApprovedWriters = () => {
  const [underwriter, setUnderwriter] = useState([]);
  const [isApprovedState, setIsApprovedState] = useState(true);

  useEffect(() => {
    const fetchUnderwriterData = async () => {
      const response = await fetch('https://resources.swmc.com/jsons/Approved_master.json'); // Update with your API endpoint
      const data = await response.json();
      const replacedData = replaceTerms(data);
      setUnderwriter(replacedData);
    };

    if (underwriter.length === 0) {
      fetchUnderwriterData();
    }
    window.scrollTo(0, 0);
  }, [underwriter]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbaApproved = await isDBAApprovedState();
        setIsApprovedState(dbaApproved);
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
        setIsApprovedState(false); // Set state accordingly
      }
    };

    fetchData();
  }, []);

  const htmlDecode = (input) => {
    var e = document.createElement('textarea');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  let underwriterTableHeads = '';
  let underwriterTableBody = '';
  let Description = '';
  let keys, values;

  if (underwriter.length > 0) {
    underwriterTableHeads = underwriter[0].multifamilyTable.table_header;
    underwriterTableBody = underwriter[0].multifamilyTable.table_contents.map((elem) => ({
      'td1': elem.td1,
      'td2': elem.td2
    }));
    keys = Object.keys(underwriter[0].multifamilyTable.description);
    values = Object.values(underwriter[0].multifamilyTable.description);
    Description = values.map((ele, index) => (
      <p style={{ fontSize: '14px' }} key={index}><sup>{keys[index]}</sup>{ele}</p>
    ));
  }

  return (
    <div className='title-underwriter-section'>
      <div className='cut-off-banner'>
        <Container className="hero_image1">
          <h1>Approved Master Title<br />Underwriters</h1>
        </Container>
      </div>
      <div>
        <ContainerWrapper>
          <Container className="px-3 mt-5">
            <Row>
              <Col md={12}>
                <TableWrapper>
                  <Table responsive bordered className="table-striped">
                    <thead>
                      <tr>
                        {underwriterTableHeads && underwriterTableHeads.map((head, index) => (
                          <th key={index}>{head}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {underwriterTableBody && underwriterTableBody.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{HTMLReactParser(row.td1)}</td>
                          <td>{HTMLReactParser(row.td2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
                <div className='notes-part'>
                  <h5>Notes: </h5>
                  {Description}
                </div>
                <h4 style={{ fontSize: '18px', textAlign: 'center', marginTop: '20px'}} >
                  <a href={isApprovedState ?
                   process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + 'document/MP-MTUL-Reg-dba.pdf' :
                   process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + 'document/MP-MTUL-notdba.pdf'
                   }
                    target="_blank"
                    rel="noopener noreferrer">
                    Click Here to Download the PDF File
                  </a>
                </h4>
              </Col>
            </Row>
          </Container>
        </ContainerWrapper>
      </div>
    </div>
  );
};

export default ApprovedWriters;