import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet';
// import ReactGA from 'react-ga';
import { isDBAApprovedState } from "../Utils";
import { Image, Container, Col, Row } from 'react-bootstrap';
import TryappImage from '../Images/truapp-img.png';
import LiabilityCap from '../Images/LiabilityCap.webp';
import dotextwithimage from '../Images/dotextwithimage.webp';
import donttextwithimage from '../Images/donttextwithimage.webp';
import {
  Main,
  Background,
  TermsBox,
  Section,
  TitleBox,
  Title,
  TextBox,
  Text,
  TextBoxCap,
  UL,
  Cap,
  CapLink,
  CapLinkText
} from './styles';
import './index.css';

export const TruTerms = () => {
  // useEffect(() => {
  //   ReactGA.pageview(location.pathname);
  // }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const [isApprovedState, setIsApprovedState] = useState(true);
  const imgCDNURL = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbaApproved = await isDBAApprovedState();
        setIsApprovedState(dbaApproved);
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
        setIsApprovedState(false); // Set state accordingly
      }
    };

    fetchData();
  }, []);

  return (
    <div className="truapppage">
      {/* <Helmet>
        <title>Mortgage Possible Mortgage Company ,Inc.-Tru Approval</title>
        <meta
          name="description"
          content="Mortgage Possible Mortgage Company ,Inc.-Tru Approval"
        />
        <meta
          property="og:url"
          content="https://www.swmc.com/mortgage-related-training-guides"
        />
        <meta
          property="og:title"
          content="SWMC - Mortgage related Training Guides"
        />
        <meta
          property="og:description"
          content="Mortgage Possible offers residential home loan mortgages such as purchase, refinance, property rehabilitation loan, and reverse mortgage."
        />
        <meta
          property="og:keyword"
          content="Mortgage related Training Guides, SWMC training, mortgage training, training guides"
        />
        <meta property="og:brand" content="Mortgage Possible Mortgage Company. Inc" />
      </Helmet> */}


      <div className="truapp">
        <Container >
          <Row className="truapprow" >
            <Col xs={12} sm={12}>
              <img src="https://resources.swmc.com/swmc-images/trueterms/truapprovalimage1.png" className="truappimagemobile" />
            </Col>
            <Col xs={12} sm={12} md={8} xl={8} lg={8}>
              <h1 className="truappheading">What is a Mortgage Possible’s<br />
                TRU Approval® program?</h1>
              <p className="truapppara1">TRU Approval® means Thoroughly Reviewed and Underwritten (TRU) Approval with a Conditional Guarantee</p>
              <p className="truapppara2">
                {isApprovedState
                  ? "Mortgage Possible NMLS 3277 (\"Mortgage Possible\")"
                  : "Mortgage Possible, a Division of Sun West Mortgage Company, Inc. NMLS 3277 (\"Mortgage Possible\")"} TRU Approval® offer has ‘No Loan Contingencies’ meaning that Mortgage Possible conditionally approves your eligibility for a mortgage based on a review of your financial information</p>
            </Col>
            <Col xs={12} sm={12} md={4} xl={4} lg={4}>
              <img src="https://resources.swmc.com/swmc-images/trueterms/truapprovalimage1.png" className="truappimage" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="truappwork">
        <Container>
          <Row className="truapprowwork">
            <Col xs={12} sm={12} md={5} xl={5} lg={5}>
              <img src="https://resources.swmc.com/swmc-images/trueterms/truapprovalimage2.png" className="truappworkimage" alt="TRU Approval Work" />
            </Col>
            <Col xs={12} sm={12} md={7} xl={7} lg={7}>
              <h3 className="truappheadingwork">How TRU Approval® works?</h3>
              <ul className="truapplist">
                <li className="trulistItem"><span className="trulistIcon"><img src='https://resources.swmc.com/swmc-images/trueterms/listimageslack.png'/></span> You express an interest in Mortgage Possible’s TRU Approval® program</li>
                <li className="trulistItem"><span className="trulistIcon"><img src='https://resources.swmc.com/swmc-images/trueterms/listimageslack.png'/></span> One of Mortgage Possible’s licensed Loan Officers contacts you to discuss your requirement and generates your TRU Approval® application</li>
                <li className="trulistItem"><span className="trulistIcon"><img src='https://resources.swmc.com/swmc-images/trueterms/listimageslack.png'/></span> Mortgage Possible reviews your credit report and financial information i.e. income and expenses, assets and liabilities etc. and determines your eligibility for a mortgage loan</li>
                <li className="trulistItem"><span className="trulistIcon"><img src='https://resources.swmc.com/swmc-images/trueterms/listimageslack.png'/></span> You receive a Mortgage Possible TRU Approval® offer</li>
                <li className="trulistItem"><span className="trulistIcon"><img src='https://resources.swmc.com/swmc-images/trueterms/listimageslack.png'/></span> When you start shopping for a property with a Mortgage Possible TRU Approval® offer in your hands, sellers don't worry about you not getting a loan</li>
                <li className="trulistItem"><span className="trulistIcon"><img src='https://resources.swmc.com/swmc-images/trueterms/listimageslack.png'/></span> A TRU Approval® offer is thus a loan approval equal in strength to a cash offer</li>
              </ul>
            </Col>
          </Row>
          <Col xs={12} sm={12} md={5} xl={5} lg={5}>
            <img src="https://resources.swmc.com/swmc-images/trueterms/truapprovalimage2.png" className="truappworkimagemobile" alt="TRU Approval Work" />
          </Col>

          <Row className="truapproworange">
            <Col xs={12} sm={12} md={5} xl={5} lg={5} className="truappcolumnorange">
              <h3 className="truappheadingorange">Avoid the Red Flags</h3>
              <div className="truapphr"></div>
              <ul className="truapplist">
                <li className="truorangelistItem"><span className="truorangeIcon"></span>Job Switch</li>
                <li className="truorangelistItem"><span className="truorangeIcon"></span>Large Purchases</li>
                <li className="truorangelistItem"><span className="truorangeIcon"></span>Missing Payments</li>
                <li className="truorangelistItem"><span className="truorangeIcon"></span>Changing Bank Accounts</li>
                <li className="truorangelistItem"><span className="truorangeIcon"></span>Co-Signing a Loan</li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={7} xl={7} lg={7}>
              <img src="https://resources.swmc.com/swmc-images/trueterms/truapprovalimage3.png" className="truapporangeimage" />
            </Col>
          </Row>
        </Container>
      </div>



      <div className="truappdoanddont">
        <Container >
          <Row className="truappdoanddontrow" >
            <Col md={12} xl={12} lg={12}>
              <p className="doanddontheading">In order to maintain your TRU Approval<sup>TM</sup> status please comply with the following "DOs and DON'Ts"</p>
            </Col>
          </Row>

          <Row className="doanddontrow" >
            <Col xs={5} sm={5} md={5} xl={5} lg={5} className="ksIxFF">
              <ol>
                <li className="lejapX">Maintain your current employment status, including consistent work hours.</li>
                <li className="lejapX">Stay connected with us through our web portal and timely respond to messages we send to you through our web portal, text, or e-mail messages.</li>
                <li className="lejapX" >Upload onto our web portal recent pay stubs, asset and account statements, and other documents we request.</li>
              </ol>
            </Col>
            <Col md={1} xl={1} lg={1}></Col>
            <Col md={5} xl={5} lg={5} className="ksIxFFnew">
              <ol>
                <li className="lejapX">Do anything that could adversely affect your credit score (such as late payments on bills or debts).</li>
                <li className="lejapX">Take on new debt or open new charge accounts.</li>
                <li className="lejapX">Move cash from account to account without first notifying us through our web portal.</li>
                <li className="lejapX">Open or close bank accounts.</li>
                <li className="lejapX">Accept gift funds from family without first discussing it with us through our web portal.</li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="truappdoanddontmobile">
        <Container >
          <Row className="truappdoanddontrowmobile" >
            <Col xs={12} sm={12}>
              <p className="doanddontheadingmobile">In order to maintain your TRU Approval<sup>TM</sup> status please comply with the following "DOs and DON'Ts"</p>
            </Col>
          </Row>

          <Row className="doanddontrowmobile" >
            <Col xs={12} sm={12} className="ksIxFFmobile1">
              <img src={dotextwithimage}
                alt="Do heading" className="dotextwithimage" />
              <ol className="doanddontcompariso">
                <li className="lejapXmobile">Maintain your current employment status, including consistent work hours.</li>
                <li className="lejapXmobile">Stay connected with us through our web portal and timely respond to messages we send to you through our web portal, text, or e-mail messages.</li>
                <li className="lejapXmobile">Upload onto our web portal recent pay stubs, asset and account statements, and other documents we request.</li>
              </ol>
            </Col>

            <Col xs={12} sm={12} className="ksIxFFmobile2">
              <img src={donttextwithimage}
                alt="Don't heading" className="donttextwithimage" />
              <ol className="doanddontcomparison">
                <li className="lejapXmobile">Do anything that could adversely affect your credit score (such as late payments on bills or debts).</li>
                <li className="lejapXmobile">Take on new debt or open new charge accounts.</li>
                <li className="lejapXmobile">Move cash from account to account without first notifying us through our web portal.</li>
                <li className="lejapXmobile">Open or close bank accounts.</li>
                <li className="lejapXmobile">Accept gift funds from family without first discussing it with us through our web portal.</li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>


      <div className="truterms">
        <Container >
          <Row className="trutermsrow" >
            <Col xs={12} sm={12} md={12} xl={12} lg={12}>

              <h3 className="trutermheading" style={{ color: "#78301D", textAlign: "center", marginTop: "-1rem", fontWeight: "900" }}>TERMS AND CONDITIONS</h3>

              <TextBox pad="2vw 2vw 0.5vw 2vw">
                <Text style={{ color: "#000000", fontSize: "1.3rem", textAlign: "justify" }}>
                  <div>
                    <ul>
                      <li className="trulistitem">A TRU Approval® offer will be null and void in the event of an adverse change in your financial circumstances, including but not limited to:</li>
                      <ul>
                        <li className="trulist1item">Loss of employment or adverse change in employment</li>
                        <li className="trulist1item">Reduction in your qualifying income</li>
                        <li className="trulist1item">You incur significant additional debt due to which debt-to-income ratio increases beyond the applicable agency limit</li>
                        <li className="trulist1item">Any information or documents you provided in order to obtain a TRU Approval® offer is or becomes untrue or materially inaccurate</li>
                        <li className="trulist1item">A change in your marital status affecting your qualifying income and or an increase in your liabilities </li>
                        <li className="trulist1item">Death of one or more applicants</li>
                      </ul>
                      <li className="trulistitem"> A TRU Approval® offer is not a Loan Estimate<sup>[1]</sup> required under the TILA-RESPA Integrated Disclosure (TRID) Rule</li>
                      <li className="trulistitem">When you select the property and express your interest to apply for a loan to purchase it, Mortgage Possible will provide you with a Loan Estimate to start the formal mortgage loan application</li>
                      <li className="trulistitem">After you express your intent to proceed with a loan application, Mortgage Possible will determine the eligibility of the property by obtaining and reviewing a Title Report and an Appraisal Report of the property</li>
                      <li className="trulistitem">A TRU Approval® offer does not eliminate appraisal conditions, Homeowners Association (“HOA”) or condominium requirements, chain of title issues, undisclosed liens and judgments etc.</li>
                      <li className="trulistitem">These and any other underwriting conditions must be satisfactorily resolved</li>
                      <li className="trulistitem">The property must meet applicable agency and loan program requirements apart from other underwriting conditions before Mortgage Possible will fund the loan</li>
                      <li className="trulistitem">Mortgage Possible reserves the right to adjust the loan parameters such as the loan amount, interest rate, Annual Percentage Rate (APR), points and fees, amortization type, loan term, and loan product etc., originally disclosed in a TRU Approval® offer, if required, during the processing of a loan application</li>
                    </ul>
                  </div>
                </Text>
              </TextBox>
            </Col>
          </Row>

          <Row className="truliabilitycap">
            <Col xs={12} sm={12} md={12} xl={12} lg={12}>
              <Text>
                <Cap >
                  <p className="cappara">
                    <img src={imgCDNURL + "Images/LiabilityCap.webp"}
                      alt="TRU Approval" class="icons-max-width icon-padding" width="45" />
                    {' '}
                    <strong className="capparaliability" >LIMITATION OF LIABILITY</strong>
                  </p>
                </Cap>
              </Text>
              <div className="limitationspara">
                <p className="truliabilitycappara">
                  To the maximum extent permitted by applicable law, in no event shall Mortgage Possible’s liability for any claim, including direct, indirect, and consequential, arising out of or in connection with a TRU Approval® offer and/or Mortgage Possible’s inability to fund a transaction that had been issued a TRU Approval® (when aggregated with Mortgage Possible’s liability for all other claims arising out of or in connection with a TRU Approval® offer) exceed the amount computed as the product of (x) three percent (3.0%) and (y) the maximum qualifying loan amount for which the TRU approval® was issued.
                  <br /><br />
                </p>
              </div>
              {/* <p className="truliabilitycappara">
                For more information about Mortgage Possible’s TRU Approval® program and/or to apply for a TRU Approval® please visit <a target="blank" href="https://www.swmc.com/truapproval/">https://www.swmc.com/truapproval/</a>
              </p> */}
              <br />
              <p className="truliabilitycappara">
                <sup>[1]</sup> What is a Loan Estimate? <a target="blank" href="https://www.consumerfinance.gov/ask-cfpb/what-is-a-loan-estimate-en-1995/">https://www.consumerfinance.gov/ask-cfpb/what-is-a-loan-estimate-en-1995/</a>
              </p>


            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );
};


export default TruTerms;