import React, { lazy, Suspense, useEffect, useState, useRef } from "react";
import axios from "axios";
import Slider from "react-slick";
import { IMG_CDN_RESOURCE } from "../Utils/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './AngelAiTraining.css';
import { Container } from "react-bootstrap";

const VideoCarousel = () => {
    const [videos, setVideos] = useState([]);
    const [selectedVideoId, setSelectedVideoId] = useState('');
    const [isMuted, setIsMuted] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const sliderRef = useRef(null);
    const videoRef = useRef(null); // Reference for video container
    const [isPlaying, setIsPlaying] = useState(false); // Track if a video is playing
    const [touchStart, setTouchStart] = useState(0); // Track the start of touch
    const [touchEnd, setTouchEnd] = useState(0); // Track the end of touch
const IMG_CDN_RESOURCE = "https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/assets/"
    

    const handleVideoPlay = (videoId) => {
        setSelectedVideoId(videoId);
        setIsPlaying(true); // Stop autoplay
        setIsLoading(true); // Start showing loader
    };
    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX); // Record the starting point of touch
    };

    const handleTouchEnd = (e) => {
        setTouchEnd(e.changedTouches[0].clientX); // Record the end point of touch
        handleSwipe();
    };

    const handleVideoStop = () => {
        setSelectedVideoId('');
        setIsPlaying(false); // Resume 
        setIsLoading(false); // Hide loader
    };
    const handleSwipe = () => {
        // If the swipe distance is significant enough (left or right), stop the video
        if (touchStart - touchEnd > 100 || touchEnd - touchStart > 100) {
            handleVideoStop(); // Stop the video on swipe
        }
    };

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_CDN+"marketing-data-service/jsons/mp-angelai-training.json");
                if (!response.ok) {
                    throw new Error('Failed to fetch videos');
                }
                const data = await response.json();
                const items = data.items || (data.data && data.data.items);
                if (!Array.isArray(items)) {
                    throw new Error('Fetched data items is not an array');
                }
                // Filter videos with non-empty thumbnails
                const filteredVideos = items.filter(video =>
                    video.snippet?.thumbnails?.maxres?.url &&
                    video.contentDetails?.videoId
                );
                setVideos(filteredVideos);
            } catch (error) {
                console.error('Error fetching or setting videos:', error);
            }
        };

        fetchVideos();
    }, []);

    const next = () => {
        handleVideoStop();  // Stop any playing video
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const previous = () => {
        handleVideoStop();  // Stop any playing video
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: false, // Stop autoplay when a video is playing
        autoplaySpeed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1500,
        centerMode: false,
        focusOnSelect: false,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ]
    };

    return (
        <>
            <div className="training-videos">
                <div className="video-carousel">
                    <Container>
                        <p className="video-carousel-heading">Your How-To Hub</p>
                        <p className="video-carousel-subheading">Quick Guides to Get you Going</p>
                    </Container>
                    <div className="d-flex justify-content-end gap-3 me-5 mt-3">
                        <img
                            src={IMG_CDN_RESOURCE + "left-arrow-white-icon.svg"}
                            width="25"
                            height="24"
                            alt="previous arrow icon"
                            onClick={previous}
                            loading="lazy"
                            className="arrows"
                        />
                        <img
                            src={IMG_CDN_RESOURCE + "right-arrow-white-icon.svg"}
                            width="25"
                            height="24"
                            alt="next arrow icon"
                            onClick={next}
                            loading="lazy"
                            className="arrows"
                        />
                    </div>
                    <Slider ref={sliderRef} {...settings} className="mt-4">
                        {videos.map((video) => (
                            <div key={video.id}>
                                {selectedVideoId === video.contentDetails.videoId ? (
                                     <div
                                     className="thumbnail-section"
                                     ref={videoRef}
                                     onTouchStart={handleTouchStart} // Start touch event
                                     onTouchEnd={handleTouchEnd} // End touch event
                                 >
                                     {isLoading && (
                                         <div className="loader">
                                             <div className="spinner"></div>
                                         </div>
                                     )}
                                    <iframe
                                        src={`https://www.youtube.com/embed/${video.contentDetails.videoId}?autoplay=1&controls=1&enablejsapi=1&mute=${isMuted ? 1 : 0}`}
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        className="angelai-training-images"
                                        title={video.snippet.title}
                                        onLoad={() => setIsLoading(false)}
                                        onEnded={handleVideoStop}
                                    ></iframe>
                                    </div>
                                ) : (
                                    <>
                                    <div className="thumbnail-section">
                                        <img
                                            src={video.snippet.thumbnails.maxres.url}
                                            alt={video.snippet.title}
                                            className="angelai-training-images"
                                            onClick={() => handleVideoPlay(video.contentDetails.videoId)}
                                        />
                                        <img
                                            src="https://prod-cdn.swmc.com/mortgagepossible/Images/playbutton.webp"
                                            className="playbutton"
                                            onClick={() => handleVideoPlay(video.contentDetails.videoId)}
                                            alt="Play button"
                                        />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default VideoCarousel;
