import React, { useState, useEffect, Suspense, lazy } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import axios from "axios";
import { IMG_CDN_RESOURCE } from "../../../../Utils";
import SignUpForm from "../SignUpForm/SignUpForm";
import "./Banner.css";

const Widgets = lazy(() => import("../../../../widgets/Widget"));
const imgCDNURL = process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE;

function SuperChargeBanner({ isMobileTablet, isHighScreenDisplay }) {
  const [bannerContent, setBannerContent] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const fetchBannerContent = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_CDN_MORTGAGE_POSSIBLE + "supercharge.json"
        );
        setBannerContent(response.data.banner);
        const imageUrl = isMobileTablet
          ? IMG_CDN_RESOURCE + response.data.banner.mobileBgImage
          : IMG_CDN_RESOURCE + response.data.banner.desktopBgImage;

        setBackgroundImage(imageUrl);
      } catch (error) {
        console.error("Error fetching banner content:", error);
      }
    };

    fetchBannerContent();
  }, [isMobileTablet]);

  if (!bannerContent) {
    return <p>Loading...</p>; // or a loader component
  }

  return (
    <div
      className="supercharge"
    >
      <Container>
        <Row>
          <Col lg={6} className="mb-3">
            <h1 className={`bannerHeading1 ${isMobileTablet ? "text-center" : ""}`}>
              Supercharge
              <span className="brown">
                {" "}
                Your
                <br />
                Mortgage Production
                <br />
                with
              </span>{" "}
              Revolutionary Ai{" "}
            </h1>
            <div>
              <p className="webinar-heading">{bannerContent.webinar.heading}</p>

              <div className="watch-webinar-btn-div">
                {bannerContent.webinar.preWebinar === "yes" ? (
                  <div className="webinar-date">
                    <p>
                      {bannerContent.webinar.date}
                      <br />
                      {bannerContent.webinar.time}
                    </p>
                  </div>
                ) : (
                  <Button
                    variant="primary"
                    href={bannerContent.webinar.webinarBtnLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="watch-webinar-btn"
                  >
                    {bannerContent.webinar.webinarBtn}
                  </Button>
                )}
              </div>
            </div>
          </Col>
          <Col lg={6} className="mb-3 supercharge-form">
            <div id="webinarForm">
              <SignUpForm />
            </div>
          </Col>
        </Row>
        <Row className="webinar-info-row">
          <Col lg={6}>
            <p className="webinar-info">
              Explore the mortgage industry's first-ever Ai tech that's set to
              revolutionize how you do business.
              <br />
              <br />
              Prepare to have your mind blown as Justin Simpers, Executive Vice
              President - Mortgage Possible, shows you how he uses{" "}
              <a
                href="https://www.swmc.com/angelai/justin"
                target="_blank"
                rel="noreferrer"
              >
                AskSimpers.Ai
              </a>{" "}
              in real time.
              <span className="yellow">
                {" "}
                You don't want to miss this incredible opportunity!
              </span>
            </p>
          </Col>
          <Col lg={6}>
            <div className="justin-block">
              <img
                src={imgCDNURL + bannerContent.justin.portrait}
                alt="Justin Simpers"
                className="justin-potrait"
              />
              <div className="justin-contact-info">
                <p className="justin-name">{bannerContent.justin.name}</p>
                <p>{bannerContent.justin.nmls}</p>
                <p>{bannerContent.justin.position}</p>
                <p>
                  D:{" "}
                  <a
                    href={`tel:+1${bannerContent.justin.contact.phone}`}
                    aria-label={bannerContent.justin.contact.phone}
                  >
                    {bannerContent.justin.contact.phone}
                  </a>
                </p>
                <p>
                  <a
                    href={`mailto:${bannerContent.justin.contact.email}`}
                    aria-label={bannerContent.justin.contact.email}
                  >
                    {bannerContent.justin.contact.email}
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="yellowdiv"></div>
      <Suspense fallback={<div />}>
        <Widgets />
      </Suspense>
    </div>
  );
}

export default SuperChargeBanner;
