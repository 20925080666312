import React, { useState, useEffect, Suspense, lazy } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { isMobileDevice, isMobileOrTablet, isUltraHighResolutionScreen, isDBAApprovedState } from "../../../Utils";
import Loading from "../../../Loading";
import MediaQuery from "react-responsive";
import SuperChargeBanner from "./Banner/SuperChargeBanner";
import NavigationBar from "./NavigationBar/NavigationBar";
import BodySection from "./BodySection/BodySection"; // No lazy-loading for critical content

const Footer = lazy(() => import("../../../Footer"));
const Testimonials = lazy(() => import("./Testimonials/Testimonials"));

function EventHome() {
    const [isMobile, setIsMobile] = useState(isMobileDevice());
    const [showOtherComponents, setShowOtherComponents] = useState(false);
    const [isMobileTablet, setIsMobileTablet] = useState(isMobileOrTablet());
    const [isHighScreenDisplay, setIsHighScreenDisplay] = useState(isUltraHighResolutionScreen());
    const [isApprovedState, setIsApprovedState] = useState(true);
    const [dependenciesRendered, setDependenciesRendered] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDependenciesRendered(true);
        }, 0);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
          const windowHeight = window.innerHeight;
          const documentHeight = document.body.offsetHeight;
          const scrollPosition =
            window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
          const maxScroll = documentHeight - windowHeight;
          const percentage = (scrollPosition / maxScroll) * 100;
    
          if (percentage > 50) {
            setShowOtherComponents(true);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    useEffect(() => {
        function handleResize() {
            setIsMobile(isMobileDevice());
            setIsMobileTablet(isMobileOrTablet());
            setIsHighScreenDisplay(isUltraHighResolutionScreen());
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dbaApproved = await isDBAApprovedState();
                setIsApprovedState(dbaApproved);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsApprovedState(false);
            }
        };

        fetchData();
    }, [isApprovedState]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const currentUrl = window.location.href;

    return (
        <div>
            <Helmet>
                <title>10x Your Mortgage Business with Revolutionary Ai</title>
                <link rel="canonical" href={currentUrl.endsWith("/") ? currentUrl : `${currentUrl}/`} />
            </Helmet>

            <NavigationBar />

            <SuperChargeBanner
                isMobile={isMobile}
                isMobileTablet={isMobileTablet}
                isHighScreenDisplay={isHighScreenDisplay}
                isApprovedState={isApprovedState}
            />

            {dependenciesRendered && (
                <>
                    <MediaQuery minWidth={992}>
                        <Suspense fallback={<Loading />}>
                            <>
                                <BodySection fetchPriority="low"/>
                                <Testimonials fetchPriority="low"/>
                                <Footer fetchPriority="low"/>
                            </>
                        </Suspense>
                    </MediaQuery>

                    {showOtherComponents && (
                        <MediaQuery maxWidth={991}>
                            <Suspense fallback={<Loading />}>
                                <>
                                    <BodySection />
                                    <Testimonials />
                                    <Footer />
                                </>
                            </Suspense>
                        </MediaQuery>
                    )}
                </>
            )}
        </div>
    );
}

export default EventHome;
