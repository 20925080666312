/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import parser from "html-react-parser";
import axios from "axios";
import { isDBAApprovedState } from "../Utils";
import "./index.css";

export default function TermsAndConditions() {
    const [termsAndConditionsData, setTermsAndConditionsData] = useState();
    const [isApprovedState, setIsApprovedState] = useState(true);

    useEffect(() => {
        /**
         * @author Pratik Kajrolkar
         * @date 04/27/2024
         * The below function is used to fetch the terms and conditions data for mortgagepossible.com which
         * is stored in master JSON in S3 bucket.
         * @returns JSON Object of the requested file
         */
        const fetchTermsAndConditions = async () => {
            try {
                await axios
                    .post(process.env.REACT_APP_TERMS_AND_CONDITIONS, { websiteName: 'mortgagepossible.com' })
                    .then((response) => {
                        // console.log(JSON.stringify(response.data.data));
                        setTermsAndConditionsData(response.data.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        };

        fetchTermsAndConditions();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dbaApproved = await isDBAApprovedState();
                setIsApprovedState(dbaApproved);
            } catch (error) {
                // Handle error
                console.error("Error fetching data:", error);
                setIsApprovedState(false); // Set state accordingly
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
              <title>
        Mortgage Possible: Your Way to Great Rates and Hassle-Free Mortgages
      </title>
            {termsAndConditionsData ? (
                <div>
                    <Container id="termsAndConditionsContainer">
                        <Row>
                            <Col sm={12}>
                                <div className="hero_image terms_image">
                                    <div>
                                        <h1>Terms and Conditions</h1>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={12} className="angelaiDisclaimer">
                                {termsAndConditionsData.angelaidisclaimer.map((angelaidisclaimer, i) => (
                                    <p key={i}>
                                        {parser(
                                            isApprovedState
                                                ? angelaidisclaimer
                                                : termsAndConditionsData.angelaidisclaimerNotdba[i]
                                        )}
                                    </p>
                                ))}
                            </Col>
                            <Col md={12}>
                                <section className="section_terms">
                                    {termsAndConditionsData.defaultDisclaimer.map((defaultDisclaimerData, i) => (
                                        <p key={i}>
                                            {i === 0 ?
                                                <span style={{ marginRight: '5px' }}>
                                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAnElEQVRIie2ROwrCUBBFj3bRdYiEENyOIKbQxh1ZJUV2JCruQ00sk+YGP+A4YPnegdede+cxA5Hg2QIdcAdSw9vJuwFzb3kGNAoWhpcDrbyVtzwBDgqVhjcFLvL23nKASqEzMDG8Wt5Rn3KxVOgBLAzPe583ZsBVwY3hvd5n7S2H596/vYGT02P0MaDDZvC9HuMf4t/EAQEMiARAD2uQL3ZXVibfAAAAAElFTkSuQmCC" alt="left quote icon" />
                                                </span>
                                                : ""}
                                            {parser(defaultDisclaimerData)}
                                            {i === termsAndConditionsData.defaultDisclaimer.length - 1 ?
                                                <span style={{ marginLeft: '5px' }}>
                                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAnElEQVRIie2SMQrCQBBFn1aSwkuIhaQIOUzAyiI2HslG65wmiJAqlxBRLE2RgZgU49827OsW3v+z7CxEZs9icv6KvuqxDL1RKHFAHDCiEb17yAX29H/+A2SOV5r3AnZq+RZ4WrB0vBR4m3dQy1dAbaHqj3cz76KWA5wt1AJrx7ua1wCJWl4wvHvueOp+RmyAhwVPjve7n6NaHpkpHbu3IYBjyh1yAAAAAElFTkSuQmCC" alt="right quote icon" />
                                                </span>
                                                : ""}
                                        </p>
                                    ))}
                                </section>
                            </Col>
                            {termsAndConditionsData.termsAndConditions.map((termsAndConditionsItem, j) => (
                                <Col md={12} key={j}>
                                    <div className="terms_header" />
                                    <div className="terms_content">
                                        <h3>{parser(termsAndConditionsItem.heading)}</h3>
                                        {termsAndConditionsItem.description.map((descriptionData, k) => (
                                            <p key={k}>
                                                {parser(descriptionData)}
                                            </p>
                                        ))}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            ) : (
                <Container>
                    <Row>
                        <Col sm={12} className="loadingSpinner text-center">
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
}