/*
 * Current Turn Times Creator for HECM, Non QM and Non conforming
 *
 */

import React, { useEffect, memo, useState } from 'react';
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import { descriptionToDisplay, fha203kProgramName, foreignNationalProgramName, imagePath } from "../Utils/WHL/helpers";


const StyleWrapper = styled.div`
.table-responsive>.table-bordered {
  border: 1px solid #FF691A!important;
}
  .table-responsive{
  margin-top: -16px;
  }
.table-bordered>:not(caption)>* {
  border-width: 1px 0 !important;
  border-color: #FF691A!important;
}
table tr th{
  background-color:#FF691A !important;
  color:#000;
  text-align:center;
  font-size: 14px;
}
tr {
  text-align: center;
}
td{
  vertical-align : middle;
  text-align:center;
   border: 1px solid #FF691A !important;
}

.initial_underwrite_Star_Header {
  color: #00008b !important;
}
.initial_underwrite_Star_Notes {
  color: #00008b !important;
}
.condition_resolution_Star_Header {
  color: #fff !important;
}
.condition_resolution_Star_Notes {
  color: #888a8c;
}
.funding_Star_Header {
  color: #ffa500 !important;
}
.funding_Star_Notes {
  color: #ffa500;
}

.two-hour-icon,
.four-hour-icon,
.eight-hour-icon,
.twenty-four-hour-icon{
  width: 40px;
}

.underWritten-on-demand {
  width: 130px;
  height: 130px;
}

.condition-review-on-demand {
  width: 130px;
  height: 130px;
}

.fourty-mins-icon {
  width: 100px;
}

.notesList{
  list-style: none;
padding-left: 0;
}
.notesList li {
  color: #939393;
}

@media (max-width:767px){
  .two-hour-icon,
  .four-hour-icon,
  .eight-hour-icon,
  .twenty-four-hour-icon{
    width: 35px;
  }
  
  .underWritten-on-demand {
    width: 100px;
    height: 100px;
  }

  .condition-review-on-demand {
    width: 100px;
    height: 100px;
  }
}


`

const TurnTimes = (props) => {


  const [turnTimesProduct, setTurnTimesProduct] = useState([]);
  const [currentTurnTimesError, setCurrentTurnTimesError] = useState(null);


  const [initial_underwriteNoteslist, setInitial_underwriteNoteslist] =
    useState([]);
  const [condition_resolutionNoteslist, setCondition_resolutionNoteslist] =
    useState([]);
  const [fundingNoteslist, setFundingNoteslist] = useState([]);

  const [
    initial_underwriteNoteEqualToAll,
    setInitial_underwriteNoteEqualToAll,
  ] = useState(false);
  const [
    condition_resolutionNoteEqualToAll,
    setCondition_resolutionNoteEqualToAll,
  ] = useState(false);
  const [fundingNoteEqualToAll, setFundingNoteEqualToAll] = useState(false);

  /* Below code is need to be uncomment for production (this is old one. Not needed now for production )
  // let loanProduct = props.location.pathname.split("/")[1];
  */



  const getPSTTimestamp = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const pstDateTime = dateTime.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    return new Date(pstDateTime);
  };


  const getCurrrentTurnTimesDetails = () => {
    // Get current turn times
    // alert("hi");

    //const programNameListWithDetails = response.data.current_times.program_name;
    const turn_times = props.timesJson
    const programNameListWithDetails = turn_times.current_times.program_name;
    // console.log("programNameListWithDetails", programNameListWithDetails)

    //--Note1:
    //--filtering programName related data base on value getting in location url (in browser url)
    //--and data getting in "REACT_APP_TURN_TIMES" API
    //--and data getting in "LoanProducts.json" file
    // const findOnlyProgramName = [programName];
    const findOnlyProgramName = props.programName
    let filtered = Object.keys(programNameListWithDetails)
      .filter((key) => findOnlyProgramName.includes(key))
      .reduce((obj, key) => {
        obj[key] = programNameListWithDetails[key];
        return obj;
      }, {});

      if(
        // props.programName === 'Non QM' ||
      props.programName === 'DSCR' ||
      props.programName === foreignNationalProgramName 
  ) {
      // let NonQM = filtered['Non QM'];

      // filtered= {
      //     "Non QM": {
      //       0: {
      //         initial_underwrite: NonQM.initial_underwrite,
      //         condition_resolution: NonQM.condition_resolution,
      //         funding: NonQM.funding,
      //       },
      //     },
      // };
  } else if(props.programName === 'Prime Jumbo' || props.programName === 'Non Conforming') {
      let PrimeJumbo = filtered['Non Conforming'];

      filtered= {
          "Non Conforming": {
            0: {
              initial_underwrite: PrimeJumbo.initial_underwrite,
              condition_resolution: PrimeJumbo.condition_resolution,
              funding: PrimeJumbo.funding,
            },
          },
      };
  } else if(props.programName === 'HECM') {
      let HECM = filtered['HECM'];

      filtered= {
          "HECM": {
            0: {
              initial_underwrite: HECM.initial_underwrite,
              condition_resolution: HECM.condition_resolution,
              funding: HECM.funding,
            },
          },
      };
  }

  //--Note 2:Converting object data into array list
  let turnTimesProductList = Object.entries(
      filtered[findOnlyProgramName]
  );

  turnTimesProductList.filter((obj) => { // WHL-32: continued from WHL-23
      if (props.programName === "HECM") {
        obj[1]["Program_Name"] = "HECM";
      } else if (props.programName === 'DSCR' ||
          props.programName === foreignNationalProgramName 
      ) {
          //-- For Non-QM  : data coming in current turn time api response is as "0" for first column. So we showing it as "Non-QM" instead of "0"  as per UI document provided.
          obj[1]["Program_Name"] = "Purchase";
      }
      else if (props.programName === "Prime Jumbo" || props.programName === 'Non Conforming') {
          obj[1]["Program_Name"] = ""
      }

      
      else {
        obj[1]["Program_Name"] = obj[0];
      }
  });

  let turnTimesProductListToShow = [];
  let i;
  for (i = 0; i < turnTimesProductList.length; i++) {
      turnTimesProductListToShow.push(turnTimesProductList[i][1]);
  }
  //--2 End
  //---
  const initial_underwrite = [];
  const condition_resolution = [];
  const funding = [];
  function showStars(count) {
      switch (count) {
          case 1:
              return "*";
          case 2:
              return "**";
          case 3:
              return "***";
          default:
              break;
      }
  } 

  let initial_underwrite_StarCount = 1;
  let condition_resolution_StarCount = 1;
  let funding_StarCount = 1;
  for (let i = 0; i < turnTimesProductListToShow.length; i++) {
    //--initial_underwrite
    turnTimesProductListToShow[i].initial_underwrite[
        "turn_time_description_"
    ] = descriptionToDisplay(
        turnTimesProductListToShow[i].initial_underwrite
    );
    if (
        turnTimesProductListToShow[i].initial_underwrite.notes.trim() !== ""
    ) {
        initial_underwrite.push({
            id: i,
            notes: turnTimesProductListToShow[i].initial_underwrite.notes,
            notes_WithStar: showStars(initial_underwrite_StarCount),
        });

        turnTimesProductListToShow[i].initial_underwrite[
            "turn_time_description__ForStar"
        ] = descriptionToDisplay(
            turnTimesProductListToShow[i].initial_underwrite
        );

        //-----
        turnTimesProductListToShow[i].initial_underwrite[
            "turn_time_description__StarToDisplay"
        ] = showStars(initial_underwrite_StarCount);
        initial_underwrite_StarCount++;
    } else {
        turnTimesProductListToShow[i].initial_underwrite[
            "turn_time_description__ForStar"
        ] = descriptionToDisplay(
            turnTimesProductListToShow[i].initial_underwrite
        );
    }
    //---condition_resolution
    turnTimesProductListToShow[i].condition_resolution[
        "turn_time_description_"
    ] = descriptionToDisplay(
        turnTimesProductListToShow[i].condition_resolution
    );
    if (
        turnTimesProductListToShow[i].condition_resolution.notes.trim() !==
        ""
    ) {
        condition_resolution.push({
            id: i,
            notes: turnTimesProductListToShow[i].condition_resolution.notes,
            notes_WithStar: showStars(condition_resolution_StarCount),
        });

        turnTimesProductListToShow[i].condition_resolution[
            "turn_time_description__ForStar"
        ] = descriptionToDisplay(
            turnTimesProductListToShow[i].condition_resolution
        );
        //--
        turnTimesProductListToShow[i].condition_resolution[
            "turn_time_description__StarToDisplay"
        ] = showStars(condition_resolution_StarCount);
        condition_resolution_StarCount++;
    } else {
        turnTimesProductListToShow[i].condition_resolution[
            "turn_time_description__ForStar"
        ] = descriptionToDisplay(
            turnTimesProductListToShow[i].condition_resolution
        );
    }
    //--funding
    turnTimesProductListToShow[i].funding["turn_time_description_"] =
        descriptionToDisplay(turnTimesProductListToShow[i].funding);
    if (turnTimesProductListToShow[i].funding.notes.trim() !== "") {
        funding.push({
            id: i,
            notes: turnTimesProductListToShow[i].funding.notes,
            notes_WithStar: showStars(funding_StarCount),
        });

        turnTimesProductListToShow[i].funding[
            "turn_time_description__ForStar"
        ] = descriptionToDisplay(turnTimesProductListToShow[i].funding);
        //--
        turnTimesProductListToShow[i].funding[
            "turn_time_description__StarToDisplay"
        ] = showStars(funding_StarCount);
        funding_StarCount++;
    } else {
        turnTimesProductListToShow[i].funding[
            "turn_time_description__ForStar"
        ] = descriptionToDisplay(turnTimesProductListToShow[i].funding);
    }

    setInitial_underwriteNoteslist(initial_underwrite);
    setCondition_resolutionNoteslist(condition_resolution);
    setFundingNoteslist(funding);
}

  function comapareObject(objects) {
      var flag = true;

      if (
          Object.keys(objects[0]).length == Object.keys(objects[1]).length &&
          Object.keys(objects[0]).length == Object.keys(objects[2]).length
      ) {
          for (let key in objects[0]) {
              if (
                  objects[0][key].trim() == objects[1][key.trim()] &&
                  objects[0][key].trim() == objects[2][key].trim()
              ) {
                  continue;
              } else {
                  flag = false;
                  break;
              }
          }
      } else {
          flag = false;
      }

      return flag;
  }

  if (initial_underwrite.length === 3) {
      const notesKeyValueOnly = initial_underwrite.map((obj) => {
          return { notes: obj.notes };
      });

      const compareResult = comapareObject(notesKeyValueOnly);
      setInitial_underwriteNoteEqualToAll(compareResult);
  }

  if (condition_resolution.length === 3) {
      const notesKeyValueOnly = condition_resolution.map((obj) => {
          return { notes: obj.notes };
      });

      const compareResult = comapareObject(notesKeyValueOnly);
      setCondition_resolutionNoteEqualToAll(compareResult);
  }

  if (funding.length === 3) {
      const notesKeyValueOnly = funding.map((obj) => {
          return { notes: obj.notes };
      });

      const compareResult = comapareObject(notesKeyValueOnly);
      setFundingNoteEqualToAll(compareResult);
  }


  let finalTurnTimesObj = turnTimesProductListToShow

  if(fha203kProgramName === props.programName) {
      finalTurnTimesObj = finalTurnTimesObj.filter(product => {
          return product.Program_Name.toLowerCase() === 'purchase' ||
          product.Program_Name.toLowerCase() === 'all other refinances'
      })
  }


  setTurnTimesProduct(finalTurnTimesObj);  
  setCurrentTurnTimesError(null);
}





  //--Note2: end

  useEffect(() => {
    getCurrrentTurnTimesDetails();


  }, []);

  function getDayName(day) {
    const arrayOfWeekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return arrayOfWeekdays[day];
  }

  function businessDayDescriptionDisplay(hours_data) {
    const turn_time_hours = hours_data;
    // check dates multiple  of 24
    const hours_multiple_timesOf24 = turn_time_hours / 24;
    let businessDayDescription = "";
    const dateTime = getPSTTimestamp(new Date());

    const dayNumber = dateTime.getDay();

    //Sunday ||   //Saturday    //Friday
    if (dayNumber === 0 || dayNumber === 6 || dayNumber === 5) {
      businessDayDescription = "By 8 AM Monday";

      ///---- upper part is correct but lower part is should be in else case---------------------
    } else {
      // Monday //Thursday

      let currentHrs = dateTime.getHours();

      if (
        currentHrs === 0 ||
        currentHrs === 20 ||
        (currentHrs > 0 && currentHrs < 20)
      ) {
        // in  business hr  //(6AM-8PM) =>  (0AM-8PM) (means 00:00:00 - 20:00:00)

        if (turn_time_hours < 24) {
          businessDayDescription = "By 8 PM today";
        } else {
          let dateTime2 = getPSTTimestamp(new Date());
          let newDateWithMultipleOf24 = dateTime2.setDate(
            dateTime2.getDate() + parseInt(hours_multiple_timesOf24)
          );
          let latestDate = new Date(newDateWithMultipleOf24);

          let whichDay = latestDate.getDay();
          //Sunday ||   //Saturday    //Friday
          if (whichDay === 0 || whichDay === 6 || whichDay === 5) {
            businessDayDescription = "By 8 AM Monday";
          } else {
            if (hours_multiple_timesOf24 === 1) {
              businessDayDescription = "By 8 AM Tomorrow";
            } else {
              businessDayDescription = "By 8 AM " + getDayName(whichDay);
            }
          }
        }
      } else {
        // after business hr (after 8PM)  like 8 PM (means 20:00:00) to 12PM (means 24:00:00) Night
        // we needs to add one more day directly as per logic recommended for after business hours
        if (turn_time_hours < 24) {
          let dateTime2 = getPSTTimestamp(new Date());

          let newDateWithTomarrow = dateTime2.setDate(dateTime2.getDate() + 1);
          let latestDate = new Date(newDateWithTomarrow);

          let whichDay = latestDate.getDay();
          //Sunday ||   //Saturday    //Friday
          if (whichDay === 0 || whichDay === 6 || whichDay === 5) {
            businessDayDescription = "By 8 AM Monday";
          } else {
            businessDayDescription = "By 8 AM Tomorrow";
          }
        } else {
          let dateTime2 = getPSTTimestamp(new Date());

          let newDateWithMultipleOf24 = dateTime2.setDate(
            dateTime2.getDate() + parseInt(hours_multiple_timesOf24) + 1
          );
          let latestDate = new Date(newDateWithMultipleOf24);

          let whichDay = latestDate.getDay();
          //Sunday ||   //Saturday    //Friday
          if (whichDay === 0 || whichDay === 6 || whichDay === 5) {
            businessDayDescription = "By 8 AM Monday";
          } else {
            businessDayDescription = "By 8 AM " + getDayName(whichDay);
          }
        }
      }
    }
    return businessDayDescription;
  }
  ///---------------

  
  //New logic for displaying notes as per the updated json
  let turn_times_json = props.timesJson;
  let notesFromJson = turn_times_json.current_times.notes;

  let programNotes = <></>;

  if(turn_times_json && notesFromJson){

  if(props.programName === "Conventional"){
    let temp = notesFromJson.Conventional;
    programNotes = temp.map((val) => {
      return (
        <li>{val}</li>
      )
    })
  }
  else if(props.programName === "FHA"){
    let temp = notesFromJson.FHA;
    programNotes = temp.map((val) => {
      return (
        <li>{val}</li>
      )
    })
  }
  else if(props.programName === "VA"){
    let temp = notesFromJson.VA;
    if(temp){
    programNotes = temp.map((val) => {
      return (
        <li>{val}</li>
      )
    })
    }
  }
  else if(props.programName === "USDA"){
    let temp = notesFromJson.USDA;
    if(temp){
    programNotes = temp.map((val) => {
      return (
        <li>{val}</li>
      )
    })
  }
  }
  else if(props.programName === "HECM"){
    let temp = notesFromJson.HECM;
    if(temp){
    programNotes = temp.map((val) => {
      return (
        <li>{val}</li>
      )
    })
  }
  }

  // else if(props.programName === "Non QM"){
  //   let temp = notesFromJson["Non QM"];
  //   if(temp){
  //   programNotes = temp.map((val) => {
  //     return (
  //       <li>{val}</li>
  //     )
  //   })
  // }
  // }

  else if(props.programName === "Non Conforming"){
    let temp = notesFromJson["Non Conforming"];
    if(temp){
    programNotes = temp.map((val) => {
      return (
        <li>{val}</li>
      )
    })
  }
  }
}

const asterikReturn = (str) => {
  let newStr = "";
  for(let i = 0; i < str.length ; i++){
    if(str[i] === "*"){
      newStr += "*"
    }
  }
  return newStr
}

  return (
    <StyleWrapper>
      <Container
      
      >
        <Col sm={12} style={{ marginTop: "0rem" }}>
        {turnTimesProduct.length > 0 ? (
                        <>
                            {/* <div className="table-title">Turn Time Commitment</div> */}
                            <Table responsive bordered >
                                <thead>
                                    <tr className="product-title">
                                        { turnTimesProduct.some(obj => (
                                            obj["Program_Name"] && obj["Program_Name"] !== "HECM" &&
                                            props.programName !== foreignNationalProgramName &&
                                            props.programName !== 'DSCR' 
                                            // && props.programName !== "Non QM"
                                        )) && <th>Loan Purpose </th>}
                                        <th>
                                        {turnTimesProduct[0].initial_underwrite.description}{" "}
                                        </th>
                                        <th>
                                        {turnTimesProduct[0].condition_resolution.description}{" "} 
                                        </th>
                                        <th>
                                        {turnTimesProduct[0].funding.description}{" "}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {turnTimesProduct.map((ttpObj, idx) => (
                                    <tr key={"turnTimesProductTbRow" + idx}>
                                        { ttpObj["Program_Name"] && ttpObj["Program_Name"] !== "HECM" &&
                                        props.programName !== foreignNationalProgramName &&
                                        props.programName !== 'DSCR' 
                                        // && props.programName !== "Non QM"
                                        &&
                                          <td>{ttpObj["Program_Name"]}</td>
                                        }
                                        {initial_underwriteNoteEqualToAll === true ? (
                                            <td>
                                                {
                                                    ttpObj["initial_underwrite"][
                                                    "turn_time_description_"
                                                    ].toLowerCase() === "on demand" ? (
                                                      <img
                                                        alt="Thoroughly Reviewed & Underwritten On Demand" 
                                                        className="underWritten-on-demand"
                                                        src='https://resources.swmc.com/swmc-images/HomeImages/underwriting-review-time.webp'
                                                      />
                                                    ) : (
                                                      <>{ttpObj["initial_underwrite"][
                                                        "turn_time_description_"
                                                        ]}
                                                      </>
                                                    )
                                                }
                                            </td>
                                        ) : (
                                            <td>
                                                {
                                                    ttpObj["initial_underwrite"][
                                                    "turn_time_description__ForStar"
                                                    ].toLowerCase() === "on demand" ? (
                                                      <img
                                                        alt="Thoroughly Reviewed & Underwritten On Demand" 
                                                        className="underWritten-on-demand"
                                                        src='https://resources.swmc.com/swmc-images/HomeImages/underwriting-review-time.webp'
                                                      />
                                                    ) : (
                                                      <>{ttpObj["initial_underwrite"][
                                                        "turn_time_description__ForStar"
                                                        ]}
                                                      </>
                                                    )
                                                }
                                                <span className="initial_underwrite_Star_Notes">
                                                    {
                                                        ttpObj["initial_underwrite"][
                                                        "turn_time_description__StarToDisplay"
                                                        ]
                                                    }
                                                </span>
                                            </td>
                                        )}                                           
                          {condition_resolutionNoteEqualToAll === true ? (
                              <td className={
                                  ttpObj["condition_resolution"]["turn_time_description"].toLowerCase()
                                                  === '1 hour' ||
                                  ttpObj["condition_resolution"]["turn_time_description"].toLowerCase()
                                  === '40 mins' ? 'two-hour-td' : ''
                              }>
                                   {
                                  ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                      === '4 hours' ?
                                      <div>
                                        <img className="four-hour-icon" src={`${imagePath}/four_hour_review.svg`} /> 
                                        <span style={{display:'inline-block',minWidth:'25px'}}>
                                        {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                          </span>
                                      </div>
                                      :
                                      ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                          === '40 mins' ?
                                          <div>
                                              <img className="fourty-mins-icon" src={`${imagePath}/40-min-logo.png`} />
                                            <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                          </span>
                                          </div>
                                      :
                                      ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                          === 'on demand' ?
                                          <div>
                                              <img className="condition-review-on-demand" src={`${process.env.REACT_APP_SWMC_IMG_CDN}HomeImages/condition-review-time.webp`} />
                                          </div>
                                           :
                                      ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                          === '1 hour' ?
                                          <div>
                                              <img className="two-hour-icon" src={`${imagePath}/one_hour_logo.png`} />
                                            <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                          </span>
                                          </div>
                                          :
                                      ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                          === '2 hours' ?
                                          <div>
                                              <img className="two-hour-icon" src={`${imagePath}/two_hour_logo_svg.svg`} />
                                            <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                          </span>
                                          </div>
                                           :
                                          ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                              === '1 business day' ?
                                              <div>
                                                <img className="twenty-four-hour-icon" src={`${imagePath}/twenty_four_hour.svg`} /> 
                                              <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                          </span>
                                              </div>
                                               :
                                          ttpObj["condition_resolution"]["turn_time_description"]
                              }
                              </td>
                          ) : (
                              <td className={
                                  ttpObj["condition_resolution"]["turn_time_description"].toLowerCase()
                                                  === '1 hour' || 
                                  ttpObj["condition_resolution"]["turn_time_description"].toLowerCase()
                                  === '40 mins' ? 'two-hour-td' : ''
                              }>
                                   {
                                      ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                          === '4 hours' ?
                                          <div>
                                            <img className="four-hour-icon" src={`${imagePath}/four_hour_review.svg`} /> 
                                            <span style={{display:'inline-block',minWidth:'25px'}}>
                                            {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                          </span>
                                          </div>
                                          :
                                          ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                              === '40 mins' ?
                                              <div>
                                                <img className="fourty-mins-icon" src={`${imagePath}/40-min-logo.png`}  />
                                                <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                            </span>
                                              </div>
                                          :
                                          ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                              === 'on demand' ?
                                              <div>
                                                <img className="condition-review-on-demand" alt='Condition Reviewed & Resolved on Demand'
                                                  src='https://resources.swmc.com/swmc-images/HomeImages/condition-review-time.webp'  />
                                              </div>
                                              :
                                          ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                              === '1 hour' ?
                                              <div>
                                                <img className="two-hour-icon" src={`${imagePath}/one_hour_logo.png`}  />
                                                <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                            </span>
                                              </div>
                                              :
                                          ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                              === '2 hours' ?
                                              <div>
                                                <img className="two-hour-icon" src={`${imagePath}/two_hour_logo_svg.svg`}  />
                                                <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                            </span>
                                              </div>
                                              :
                                              ttpObj["condition_resolution"]["turn_time_description"].toLowerCase().split('*')[0]
                                                  === '1 business day' ?
                                                  <div>
                                                    <img className="twenty-four-hour-icon" src={`${imagePath}/twenty_four_hour.svg`} /> 
                                                    <span style={{display:'inline-block',minWidth:'25px'}}>
                                              {asterikReturn( ttpObj["condition_resolution"]["turn_time_description"].toLowerCase())}
                                                </span>
                                                  </div>
                                                   :
                                              ttpObj["condition_resolution"]["turn_time_description"]
                                  }
                                  <span className="condition_resolution_Star_Notes">
                                      {
                                          ttpObj["condition_resolution"][
                                          "turn_time_description__StarToDisplay"
                                          ]
                                      }
                                  </span>
                              </td>
                          )}
                                            {fundingNoteEqualToAll === true ? (
                                                <td>{ttpObj["funding"]["turn_time_description"]}</td>
                                            ) : (
                                                <td>
                                                    {ttpObj["funding"]["turn_time_description"]}
                                                    <span className="funding_Star_Notes">
                                                        {
                                                            ttpObj["funding"][
                                                            "turn_time_description__StarToDisplay"
                                                            ]
                                                        }
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>{" "}
                            {currentTurnTimesError && (
                                <div className="errorMsg">{currentTurnTimesError}</div>
                            )}
                        {programNotes ? <ul className="notesList">{programNotes}</ul> : null}
                            {/* <p className="pt-0 mt-n2" style={{ color: '#939393' }}>
                                <span className="initial_underwrite_Star_Notes"></span> <sup>*</sup>Any
                                file submitted before 10 am PST will be funded the same day
                            </p>                       */}
                        </>
                    ) : null}
        </Col>
      </Container>
    </StyleWrapper>
  )
}


export default (TurnTimes);