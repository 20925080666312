/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useState, useEffect, Suspense, lazy } from "react";
import { isMobileDevice, isMobileOrTablet, isHighResolutionScreen, isDBAApprovedState } from "../Utils";
import { useLocation } from 'react-router-dom';  // Add this to get the current route path
import Loading from "../Loading";
import Helmet from "react-helmet";
import MediaQuery from "react-responsive";
import Banner from "../Banner";

const Difference = lazy(() => import("../Difference"));
const WebinarVideos = lazy(() => import("../WebinarVideos/WebinarVideos"));
const PowerOfAi = lazy(() => import("../PowerOfAi"));
const InfinitePossibilities = lazy(() => import("../InfinitePossibilities"));
const AskSimpersAi = lazy(() => import("../AskSimpersAi"));
const PartnerWithUs = lazy(() => import("../PartnerWithUs"));
const AngelaiTraining = lazy(() => import("../AngelAiTraining/AngelAiTraining"));
const Footer = lazy(() => import("../Footer"));

function Home() {
    const [isMobile, setIsMobile] = useState(isMobileDevice());
    const [showOtherComponents, setShowOtherComponents] = useState(false);
    const [isMobileTablet, setIsMobileTablet] = useState(isMobileOrTablet());
    const [isHighScreenDisplay, setIsHighScreenDisplay] = useState(isHighResolutionScreen());
    const [isApprovedState, setIsApprovedState] = useState(true);
    const mediaQueryDesktop = window.matchMedia("(min-width: 992px)");
  const mediaQueryMobile = window.matchMedia("(max-width: 991px)");
    const location = useLocation();  // Get the current route path

    useEffect(() => {
        const handleScroll = () => {
          const windowHeight = window.innerHeight;
          const documentHeight = document.body.offsetHeight;
          const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
          const maxScroll = documentHeight - windowHeight;
          const scrollPercentage = (scrollPosition / maxScroll) * 100;
          if (scrollPercentage > 10) {
            setShowOtherComponents(true);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    useEffect(() => {
        function handleResize() {
            setIsMobile(isMobileDevice());
            setIsMobileTablet(isMobileOrTablet());
            setIsHighScreenDisplay(isHighResolutionScreen());
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dbaApproved = await isDBAApprovedState();
                setIsApprovedState(dbaApproved);
            } catch (error) {
                // Handle error
                console.error("Error fetching data:", error);
                setIsApprovedState(false); // Set state accordingly
            }
        };

        fetchData();
    }, [isApprovedState]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
            <Helmet>
                <title>Mortgage Possible: Your Way to Great Rates and Hassle-Free Mortgages</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <Banner
                isMobile={isMobile}
                isHighScreenDisplay={isHighScreenDisplay}
                showLearnMoreBtn={true}
                isApprovedState={isApprovedState}
            />

            <Suspense fallback={<Loading />}>
                <Difference
                    isMobileTablet={isMobileTablet}
                    isApprovedState={isApprovedState}
                />
            </Suspense>
            {mediaQueryDesktop.matches && (
          <>
                <Suspense fallback={<Loading />}>
                <WebinarVideos
                    isMobileTablet={isMobileTablet}
                    isApprovedState={isApprovedState}
                />
            </Suspense>
           
                <Suspense fallback={<div />}>
                    <PowerOfAi
                        isMobile={isMobile}
                    />
                </Suspense>
                {showOtherComponents && (
            <>  
                <Suspense fallback={<div />}>
                    <InfinitePossibilities
                        isMobile={isMobile}
                        isMobileTablet={isMobileTablet}
                    />
                </Suspense>
                <Suspense fallback={<div />}>
                    <AskSimpersAi
                        isMobileTablet={isMobileTablet}
                        isHighScreenDisplay={isHighScreenDisplay}
                    />
                </Suspense>
                <Suspense fallback={<div />}>
                    <AngelaiTraining
                        isMobileTablet={isMobileTablet}
                    />
                </Suspense>
                <Suspense fallback={<div />}>
                    <PartnerWithUs
                        isMobileTablet={isMobileTablet}
                        isApprovedState={isApprovedState}
                    />
                </Suspense>
                <Suspense fallback={<div />}>
                    <Footer />
                </Suspense>
                </>
            )}
                </>
        )}
            {mediaQueryMobile.matches && showOtherComponents && (
            <>
                    <Suspense fallback={<Loading />}>
                <WebinarVideos
                    isMobileTablet={isMobileTablet}
                    isApprovedState={isApprovedState}
                />
            </Suspense>
                    <Suspense fallback={<div />}>
                        <PowerOfAi
                            isMobile={isMobile}
                        />
                    </Suspense>
                    <Suspense fallback={<div />}>
                        <InfinitePossibilities
                            isMobile={isMobile}
                            isMobileTablet={isMobileTablet}
                        />
                    </Suspense>
                    <Suspense fallback={<div />}>
                        <AskSimpersAi
                            isMobileTablet={isMobileTablet}
                            isHighScreenDisplay={isHighScreenDisplay}
                        />
                    </Suspense>
                    <Suspense fallback={<div />}>
                        <AngelaiTraining
                            isMobileTablet={isMobileTablet}
                        />
                    </Suspense>
                    <Suspense fallback={<div />}>
                        <PartnerWithUs
                            isMobileTablet={isMobileTablet}
                            isApprovedState={isApprovedState}
                        />
                    </Suspense>
                    <Suspense fallback={<div />}>
                        <Footer />
                    </Suspense>
                    </>
        )}
        </div>
    )
}
export default Home